import React from 'react';
import { Paper } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import EliasDataGrid from '../../atoms/EliasDataGrid/EliasDataGrid';
import './PlayerStreakTable.scss';
import { PlayerStreakResult, ResultDetail } from '../../../api/types';
import { createCsvString, formatResultDetail } from '../../utils/common/results';
import CopyButton from '../../atoms/CopyButton/CopyButton';
import FavoriteButton from '../../atoms/FavoriteButton/FavoriteButton';
import { ExternalLeagueRoutesType } from '../../utils/router/external_urls';

interface PlayerStreakTableProps {
  data: PlayerStreakResult[];
  dataCount: number;
  dataMetaInfo: ResultDetail[];
  columnData: any;
  handleDone: (sortByParam: string, pageParam: number) => void;
  linkUrls: ExternalLeagueRoutesType;
  explanationMessage?: React.ReactElement;
}

function PlayerStreakTable({
  data,
  dataCount,
  dataMetaInfo,
  columnData,
  handleDone,
  linkUrls,
  explanationMessage,
}: PlayerStreakTableProps) {
  const total = dataCount;
  const defaultExplantationMessation = (
    <p className="grid-explanation">
      {formatResultDetail(dataMetaInfo)}
    </p>
  );
  const resultExplanationText = explanationMessage || defaultExplantationMessation;

  const copyCsvDataFromResults = () => {
    const columnHeaders = columnData.map((column: any) => column.headerName);
    const fields = columnData.map((column: any) => column.field);
    const csv = createCsvString(columnHeaders, fields, data);
    navigator.clipboard.writeText(csv);
  };

  return (
    <Paper elevation={3}>
      <Grid2 container spacing={2} minHeight={75}>
        <Grid2 xs={2} display="flex" justifyContent="center" alignItems="center">
          <h3 className="grid-total player-game-results-total">{total} Results</h3>
        </Grid2>
        <Grid2 xs={6} display="flex" justifyContent="flex-start" alignItems="center">
          { resultExplanationText }
        </Grid2>
        <Grid2 xs={4} display="flex" justifyContent="center" alignItems="center">
          <FavoriteButton />
          <CopyButton onClick={copyCsvDataFromResults} />
        </Grid2>
      </Grid2>
      <EliasDataGrid
        columns={columnData}
        rows={data}
        rowCount={total}
        handleDone={handleDone}
        linkUrls={linkUrls}
      />
    </Paper>
  );
}

export default PlayerStreakTable;
