import React from 'react';
import { GridValidRowModel } from '@mui/x-data-grid/models';
import { DEFAULT_PAGE_SIZE } from '../../../../api/api';
import { GroupedPlayerInstance, GroupedTeamInstance, PlayerGameResult, BasePlayerGameTableRow, BaseTeamGameResult, TeamGameTableRow } from '../../../../api/types';
import { percentToFixed } from '../../common/string';
import EXTERNAL_URLS from '../../router/external_urls';

export const generateRowNumber = (
  rowID: number,
  page: number,
  pageSize = DEFAULT_PAGE_SIZE
) => (page === 0 ? rowID + 1 : pageSize * page + rowID + 1);

export const dedupeRows = (allRows: GridValidRowModel) => {
  const ids = allRows.map((o: GridValidRowModel) => o.id);
  const filteredRows = allRows.filter(
    ({ id }: { id: string }, index: number) => !ids.includes(id, index + 1)
  );
  return filteredRows;
};

const calculateStarted = (incoming: boolean) : number => {
  if (incoming !== null) {
    return (incoming) ? 1 : 0;
  }
  return incoming;
};

const mapHomeAway = (homeAway: string): string => {
  switch (homeAway) {
    case 'R':
      return ' @ ';
    case 'N':
      return ' N ';
    default:
      return ' vs '
  }
};

const generateFranchiseLink = (franchiseId: number, franchiseAbbr: string): JSX.Element => franchiseId ? <a href={EXTERNAL_URLS.NBA.franchiseProfile.replace(':teamId', franchiseId.toString())} target="_blank" rel="noreferrer">{franchiseAbbr}</a> : <span>{franchiseAbbr}</span>;

export const formatResult = (
  franchiseAbbr: string,
  opponentAbbr: string,
  homeAway: string,
  result: string,
  franchiseScore: number,
  opponentScore: number,
  overtime: boolean,
  franchiseId: number,
  opponentId: number,
): JSX.Element => {
  const overtimeDisplay = overtime ? ' (OT)' : '';
  const homeAwayDisplay = mapHomeAway(homeAway);
  const franchiseDisplay = generateFranchiseLink(franchiseId, franchiseAbbr);
  const opponentDisplay = generateFranchiseLink(opponentId, opponentAbbr);
  const teams = <span>{franchiseDisplay}{homeAwayDisplay}{opponentDisplay} {result} {franchiseScore}-{opponentScore} {overtimeDisplay}</span>;
  return teams;
};

const formatGameClassDisplayAndRound = (
  gameClassDisplay?: string | null,
  round?: string | number,
): string => {
  if (!gameClassDisplay) return '';

  if (round && gameClassDisplay !== 'REG') {
    if (typeof round === 'string' && round.length > 0) {
      return `${gameClassDisplay}#${round}`
    }
    if (typeof round === 'number' && round > 0) {
      return `${gameClassDisplay}#${round}`
    }
  }

  return gameClassDisplay;
};

export const mapTeamUngroupedRows = (
  results: BaseTeamGameResult[],
  page: number
): TeamGameTableRow[] =>
  results.map((row: BaseTeamGameResult, index: number) => ({
    // not a stat
    rank: generateRowNumber(index, page),

    assists: row.assists,
    blocks: row.blocks,
    defensive_rebounds: row.defensive_rebounds,
    double_double: row.double_double ? 'T' : 'F',
    dunks: row.dunks,
    field_goal_attempts: row.field_goal_attempts,
    field_goals_difference: row.field_goals_difference,
    field_goals_made: row.field_goals_made,
    field_goal_percentage: percentToFixed(row.field_goal_percentage, 3),
    field_goal_percentage_difference: percentToFixed(row.field_goal_percentage_difference, 3),
    franchise_display_name: row.franchise.full_name,
    franchise_rank: row.franchise_rank,
    franchise_score: row.franchise_score,
    franchise_season__abbr_3: row.franchise.abbr_3,
    franchise_win_percentage: row.franchise_win_percentage,
    free_throw_attempts: row.free_throw_attempts,
    free_throws_difference: row.free_throws_difference,
    free_throws_made: row.free_throws_made,
    free_throw_percentage: percentToFixed(row.free_throw_percentage, 3),
    game__date: row.game.date,
    game__game_class: formatGameClassDisplayAndRound(
      row.game.game_class,
      row.game_franchise?.game_number,
    ),
    id: row.id,
    loc: mapHomeAway(row.home_game as string),
    last_game_result: row.last_game_result,
    minutes_display: row.minutes_display,
    offensive_rebounds: row.offensive_rebounds,
    opponent_rank: row.opponent_rank,
    opponent_score: row.opponent_score,
    opponent_season__abbr_3: row.opponent.abbr_3 || row.opponent_abbr,
    opponent_win_percentage: row.opponent_win_percentage,
    personal_fouls: row.personal_fouls,
    personal_fouls_difference: row.personal_fouls_difference,
    plus_minus: row.plus_minus,
    points: row.points,
    points_difference: row.points_difference,
    points_in_paint: row.points_in_paint,
    points_off_turnovers: row.points_off_turnovers,
    record_type: row.record_type || '',
    result: formatResult(
      row.franchise.abbr_3,
      row.opponent.abbr_3 || row.opponent_abbr,
      row.home_game as string,
      row.game_result,
      row.franchise_score,
      row.opponent_score,
      row.game.overtime_played,
      row.franchise.franchise_id,
      row.opponent.franchise_id,
    ),
    round: row.game.tournament_round || "",
    score_difference: row.game_franchise?.score_difference,
    season_game_number: row.season_game_number,
    second_chance_points: row.second_chance_points,
    steals: row.steals,
    steals_difference: row.steals_difference,
    three_point_field_goal_attempts: row.three_point_field_goal_attempts,
    three_point_field_goals_difference: row.three_point_field_goals_difference,
    three_point_field_goals_made: row.three_point_field_goals_made,
    three_point_field_goal_percentage: percentToFixed(row.three_point_field_goal_percentage, 3),
    total_rebounds: row.total_rebounds,
    total_rebounds_difference: row.total_rebounds_difference,
    triple_double: row.triple_double ? 'T' : 'F',
    turnovers: row.turnovers,
    turnovers_difference: row.turnovers_difference,
    win_percentage_difference: row.win_percentage_difference,
    opponent_points_half_1: row.opponent_points_half_1,
    opponent_points_half_2: row.opponent_points_half_2,
    points_diff_half_1: row.points_diff_half_1,
    points_diff_half_2: row.points_diff_half_2,
    points_half_1: row.points_half_1,
    points_half_2: row.points_half_2,

    // opening_day: row.opening_day ? 1 : 0,

    // the following fields will not display but are needed for rendering
    // other things
    franchise_id: row.franchise.franchise_id,
    opponent_id: row.opponent.franchise_id,
    game_id: row.game.id,

  }));

export const mapUngroupedRows = (results: PlayerGameResult[], page: number): BasePlayerGameTableRow[] =>
  results.map((row: PlayerGameResult, index: number) => ({
    id: row.id,
    rank: generateRowNumber(index, page),
    player__display_name: row.player?.display_name,
    player__player_id: row.player.id,
    age: row.age,
    game__date: row.game.date,
    franchise_season__abbr_3: row.franchise.abbr_3,
    loc: row.home_game ? '' : '@',
    opponent_season__abbr_3: row.opponent.abbr_3,
    result: formatResult(
      row.franchise.abbr_3,
      row.opponent.abbr_3 || row.opponent_abbr,
      row.home_game as string,
      row.game_result,
      row.franchise_score,
      row.opponent_score,
      row.game.overtime_played,
      row.franchise.franchise_id,
      row.opponent.franchise_id,
    ),
    record_type: row.record_type || '',
    game__game_class: formatGameClassDisplayAndRound(row.game.game_class, row.game_franchise?.game_number),
    started: calculateStarted(row.started),
    minutes_display: row.minutes_display,
    field_goals_made: row.field_goals_made,
    field_goal_attempts: row.field_goal_attempts,
    field_goal_percentage: percentToFixed(row.field_goal_percentage, 3),
    three_point_field_goals_made: row.three_point_field_goals_made,
    three_point_field_goal_attempts: row.three_point_field_goal_attempts,
    three_point_field_goal_percentage: percentToFixed(row.three_point_field_goal_percentage, 3),
    free_throws_made: row.free_throws_made,
    free_throw_attempts: row.free_throw_attempts,
    free_throw_percentage: percentToFixed(row.free_throw_percentage, 3),
    steals: row.steals,
    blocks: row.blocks,
    turnovers: row.turnovers,
    personal_fouls: row.personal_fouls,
    total_rebounds: row.total_rebounds,
    offensive_rebounds: row.offensive_rebounds,
    defensive_rebounds: row.defensive_rebounds,
    assists: row.assists,
    plus_minus: row.plus_minus,
    points: row.points,
    double_double: row.double_double ? 'T' : 'F',
    triple_double: row.triple_double ? 'T' : 'F',
    franchise_score: row.franchise_score,
    opponent_score: row.opponent_score,
    score_difference: row.game_franchise?.score_difference,
    opening_day: row.opening_day ? 1 : 0,
    points_in_paint: row.points_in_paint,
    points_off_turnovers: row.points_off_turnovers,
    second_chance_points: row.second_chance_points,
    dunks: row.dunks,

    // the following fields will not display but are needed for rendering
    // other things
    franchise_id: row.franchise.franchise_id,
    opponent_id: row.opponent.franchise_id,
    game_id: row.game.id,
  }));

export const getGameDateFromID = (gameID: number) => {
  if (!gameID) return '';
  // Converts the GameID which is in the YYYYMMMDDGG format to game date format YYYY-MM-DD
  const date = gameID.toString().slice(0, -2);
  const formattedDate = date.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
  return formattedDate;
};

export const mapPlayerGroupedRows = (
  results: GroupedPlayerInstance[],
  page: number
) =>
  results.map((row: GroupedPlayerInstance, index: number) => ({
    rank: generateRowNumber(index, page),
    count: row.count,
    display_name: row.display_name,
    first: getGameDateFromID(row.first),
    first_id: row.first,
    last: getGameDateFromID(row.last),
    last_id: row.last,
    player_id: row.player_id,
  }));

export const mapPlayerSeasonRows = (
  results: GroupedPlayerInstance[],
  page: number
) =>
  results.map((row: GroupedPlayerInstance, index: number) => ({
    rank: generateRowNumber(index, page),
    count: row.count,
    display_name: row.display_name,
    first: getGameDateFromID(row.first),
    first_id: row.first,
    last: getGameDateFromID(row.last),
    last_id: row.last,
    season: row.season,
    player_id: row.player_id,
  }));

export const mapPlayerFranchiseRows = (
  results: GroupedPlayerInstance[],
  page: number
) =>
  results.map((row: GroupedPlayerInstance, index: number) => ({
    rank: generateRowNumber(index, page),
    count: row.count,
    display_name: row.display_name,
    first: getGameDateFromID(row.first),
    first_id: row.first,
    last: getGameDateFromID(row.last),
    last_id: row.last,
    franchise_name: row.franchise?.full_name,
    player_id: row.player_id,
  }));

export const mapPlayerOpponentRows = (
  results: GroupedPlayerInstance[],
  page: number
) =>
  results.map((row: GroupedPlayerInstance, index: number) => ({
    rank: generateRowNumber(index, page),
    count: row.count,
    display_name: row.display_name,
    first: getGameDateFromID(row.first),
    first_id: row.first,
    last: getGameDateFromID(row.last),
    last_id: row.last,
    opponent_name: row.opponent?.full_name,
    player_id: row.player_id,
  }));

export const mapPlayerFranchiseSeasonRows = (
  results: GroupedPlayerInstance[],
  page: number
) =>
  results.map((row: GroupedPlayerInstance, index: number) => ({
    rank: generateRowNumber(index, page),
    count: row.count,
    display_name: row.display_name,
    first: getGameDateFromID(row.first),
    first_id: row.first,
    last: getGameDateFromID(row.last),
    last_id: row.last,
    franchise_name: row.franchise?.full_name,
    season: row.season,
    player_id: row.player_id,
  }));

export const mapPlayerOpponentSeasonRows = (
  results: GroupedPlayerInstance[],
  page: number
) =>
  results.map((row: GroupedPlayerInstance, index: number) => ({
    rank: generateRowNumber(index, page),
    count: row.count,
    display_name: row.display_name,
    first: getGameDateFromID(row.first),
    first_id: row.first,
    last: getGameDateFromID(row.last),
    last_id: row.last,
    opponent_name: row.opponent?.full_name,
    season: row.season,
    player_id: row.player_id,
  }));

export const mapTeamInstanceRows = (
  results: GroupedTeamInstance[],
  page: number
) =>
  results.map((row: GroupedTeamInstance, index: number) => ({
    rank: generateRowNumber(index, page),
    count: row.count,
    franchise_display_name: row.franchise.full_name,
    first: getGameDateFromID(row.first),
    first_id: row.first,
    last: getGameDateFromID(row.last),
    last_id: row.last,
    franchise_id: row.franchise.franchise_id,
  }));

export const mapTeamSeasonRows = (
  results: GroupedTeamInstance[],
  page: number
) =>
  results.map((row: GroupedTeamInstance, index: number) => ({
    rank: generateRowNumber(index, page),
    count: row.count,
    franchise_display_name: row.franchise.full_name,
    first: getGameDateFromID(row.first),
    first_id: row.first,
    last: getGameDateFromID(row.last),
    last_id: row.last,
    season: row.season,
    franchise_id: row.franchise.franchise_id,
    opponent_id: row.opponent.franchise_id,
  }));

export const mapTeamOpponentRows = (
  results: GroupedTeamInstance[],
  page: number
) =>
  results.map((row: GroupedTeamInstance, index: number) => ({
    rank: generateRowNumber(index, page),
    count: row.count,
    franchise_display_name: row.franchise.full_name,
    first: getGameDateFromID(row.first),
    first_id: row.first,
    last: getGameDateFromID(row.last),
    last_id: row.last,
    opponent_name: row.opponent?.full_name,
    franchise_id: row.franchise.franchise_id,
    opponent_id: row.opponent.franchise_id,
  }));

export const mapPlayerRows = (
  results: PlayerGameResult[] | GroupedPlayerInstance[],
  groupByColumn: string,
  page: number
) => {
  switch (groupByColumn) {
    case 'player':
      return mapPlayerGroupedRows(results as GroupedPlayerInstance[], page);
    case 'player-season':
      return mapPlayerSeasonRows(results as any, page);
    case 'player-franchise':
      return mapPlayerFranchiseRows(results as any, page);
    case 'player-opponent':
      return mapPlayerOpponentRows(results as any, page);
    case 'player-franchise-season':
      return mapPlayerFranchiseSeasonRows(results as any, page);
    case 'player-opponent-season':
      return mapPlayerOpponentSeasonRows(results as any, page);
    default:
      return mapUngroupedRows(dedupeRows(results), page);
  };
};

export const mapTeamRows = (
  results: PlayerGameResult[] | GroupedPlayerInstance[],
  groupByColumn: string,
  page: number
) => {
  switch (groupByColumn) {
    case 'team':
      return mapTeamInstanceRows(results as any, page);
    case 'team-season':
      return mapTeamSeasonRows(results as any, page);
    case 'team-opponent':
      return mapTeamOpponentRows(results as any, page);
    default:
      return mapTeamUngroupedRows(dedupeRows(results), page);
  };
};

export const mapRows = (
  results: PlayerGameResult[] | GroupedPlayerInstance[],
  groupByColumn: string,
  page: number,
  rowType: string = 'player',
) => {
  switch (rowType) {
    case 'player':
      return mapPlayerRows(results, groupByColumn, page);
    case 'team':
      return mapTeamRows(results, groupByColumn, page);
    default:
      return mapPlayerRows(results, groupByColumn, page);
  };
};
