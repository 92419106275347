import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/system/Box';
import { IconButton, Paper } from '@mui/material';
import SwapVerticalCircleOutlinedIcon from '@mui/icons-material/SwapVerticalCircleOutlined';
import './PlayerFiltersLayout.scss';
import SearchButtonStyles from './StyleOverrides';
import { useFilterContext, useFilterDispatchContext } from '../../contexts/FilterContext';
import EliasNameFilter from '../EliasNameFilter/EliasNameFilter';
import MultiPlayerFilter from '../MultiPlayerFilter/MultiPlayerFilter';
import TeamFilter from '../TeamFilter/TeamFilter';
import VenueFilter from '../VenueFilter/VenueFilter';
import { searchEnabled } from '../../utils/nba/search';
import { IApiUrlFinders } from '../../../api/api_urls';
import { ContextFilterType } from '../../types/context';
import FastFilters from '../FastFilters/FastFilters';
import ResetButton from '../../atoms/ResetButton/ResetButton';


interface IProps {
  handleDone: () => void;
  pageSpecificSearchEnabled: (context: ContextFilterType) => boolean,

  statsFilterPartial: React.ReactNode;
  groupByPartial?: React.ReactNode;
  topModalPartial?: React.ReactNode;
  bottomModalPartial?: React.ReactNode;

  children?: React.ReactElement;

  apiUrls: IApiUrlFinders,
  showHalfQuarter?: boolean;
}

function PlayerFiltersLayout({
  handleDone,
  pageSpecificSearchEnabled,
  statsFilterPartial,
  groupByPartial,

  children,
  apiUrls,
  topModalPartial,
  bottomModalPartial,
  showHalfQuarter=false,
}: IProps) {
  const filterContext: any = useFilterContext();
  const filterDispatchContext: any = useFilterDispatchContext();

  const clickPlayerSwap = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const player = filterContext.playerObject ? {...filterContext.playerObject} : null;
    const opposingPlayer = filterContext.opposingPlayerObject ? {...filterContext.opposingPlayerObject} : null;

    filterDispatchContext({
      type: 'changePlayerObject',
      playerObject: opposingPlayer,
      searchEnabled: true,
    });

    filterDispatchContext({
      type: 'changeOpposingPlayerObject',
      opposingPlayerObject: player,
      searchEnabled: true,
    });
  };

  return (
    <Paper elevation={3}>
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        paddingX={5}
        paddingY={2}
      >
        <FastFilters
          showActive
          showRookie
          showHomeAway
          showWinLoss
          showOvertime
          hideNeutralSite
          showHalfQuarter={showHalfQuarter}
          halfQuarterExplanationText='Splits since 1997-98'
        />
        { topModalPartial }
        <Grid item xs={4}>
          { statsFilterPartial }
        </Grid>
        <Grid item xs={4}>
          <EliasNameFilter
            label="Player"
            actionType='changePlayerObject'
            actionObject='playerObject'
            apiUrl={apiUrls.players_manifest}
          />
          <Box sx={{ position: 'absolute', zIndex: 100 }}>
            <IconButton
              sx={{
                padding: 0,
                position: 'relative',
                bottom: '10px',
                left: '10px',
              }}
              onClick={clickPlayerSwap}
            >
              <SwapVerticalCircleOutlinedIcon
                fontSize='large'
                sx={{
                  color: '#667085',
                  backgroundColor: '#fff',
                  borderRadius: 10,
                  '&:hover': {
                    backgroundColor: '#f2f4f7',
                  },
                }}
              />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <TeamFilter
            label="Player's Team"
            actionType='changeTeamObject'
            actionObject='teamObject'
            apiUrl={apiUrls.franchise_season_manifest}
          />
        </Grid>
        <Grid item xs={4}>
          <MultiPlayerFilter
            label="Add one or more teammates"
            actionType='changePlayerTeammates'
            actionObject='playerTeammates'
            apiUrl={apiUrls.players}
          />
        </Grid>
        <Grid item xs={4}>
          <EliasNameFilter
            label="Opposing Player"
            actionType='changeOpposingPlayerObject'
            actionObject='opposingPlayerObject'
            apiUrl={apiUrls.players}
          />
        </Grid>
        <Grid item xs={4}>
          <TeamFilter
            label="Opposing Team"
            actionType='changeOpponentObject'
            actionObject='opponentObject'
            apiUrl={apiUrls.franchise_season_manifest}
            altIcon
          />
        </Grid>
        <Grid item xs={4}>
          <VenueFilter apiUrl={apiUrls.venue_manifest} />
        </Grid>
        { children }
        { bottomModalPartial }
        <Grid item xs={12} paddingTop='0px'>
          <Box className="horizontal-divider" />
        </Grid>
        <Grid container item xs={12} className="filter-buttons">
          <Grid item xs={1}>
            <ResetButton />
          </Grid>
          <Grid item xs={10} textAlign="right" paddingRight={5}>
            { groupByPartial }
          </Grid>
          <Grid item xs={1}>
            <Button
              sx={SearchButtonStyles}
              disabled={!searchEnabled(filterContext, pageSpecificSearchEnabled)}
              variant="contained"
              onClick={handleDone}
            >
              {!filterContext.searchEnabled ? 'Update' : 'Search'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default PlayerFiltersLayout;
