import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Chip, InputAdornment, styled } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import CancelRounded from '@mui/icons-material/CancelRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/system/Box';
import { PlayerInfo } from '../../../api/types';
import './EliasNameFilter.scss';
import { useFilterContext, useFilterDispatchContext } from '../../contexts/FilterContext';
import { useFetchPlayers } from '../../hooks/useFetch';

const StyledAutocomplete = styled(Autocomplete)((props: { textcolor: string, weight: number  }) => ({
  '.MuiInputBase-root': {
    paddingLeft: '35px !important',
    '.MuiAutocomplete-input': {
      color: props.textcolor,
      fontWeight: props.weight,
    },
    '.MuiAutocomplete-endAdornment': {
      '.MuiAutocomplete-clearIndicator': {
        '.cancel-icon': {
          color: props.textcolor,
          visibility: 'visible',
        }
      }
    },
  },
  '.MuiChip-root': {
    color: '#175CD3',
    backgroundColor: '#EFF8FF',
    '& .MuiChip-deleteIcon': {
      color: '#175CD3',
      fontSize: '1.25em',
    },
  },
}));

interface IProps {
  label: string;
  actionType: string;
  actionObject: string;
  apiUrl: string;
}

function MultiPlayerFilter({
  label,
  actionType,
  actionObject,
  apiUrl
}: IProps) {
  const filterContext: any = useFilterContext();
  const filterDispatchContext: any = useFilterDispatchContext();

  const [inputValue, setInputValue] = React.useState('');

  const { data: playerData } = useFetchPlayers(inputValue, apiUrl);

  const handleChange = (_event: any, newValue: PlayerInfo[] | null) => {
    filterDispatchContext({
      type: actionType,
      [actionObject]: newValue,
      searchEnabled: true,
    });
  };

  const handleInputChange = (_event: any, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const textColor = filterContext[actionObject] &&
    filterContext[actionObject].length > 0 ? '#175CD3' : '#101828';

  const fontweight = filterContext[actionObject] &&
    filterContext[actionObject].length > 0 ? 500 : 400;

  return (
    <Box>
      <StyledAutocomplete
        id="elias-name-filter"
        data-testid="elias-name-filter"
        options={playerData?.results ?? []}
        value={filterContext[actionObject]}
        includeInputInList
        filterOptions={(x) => x}
        isOptionEqualToValue={(option: any) => {
          if (filterContext[actionObject] && filterContext[actionObject].length > 0) {
            const ids = filterContext[actionObject].map((selected: any) => selected.id);
            return ids.includes(option.id);
          }
          return false;
        }}
        noOptionsText="No matches found."
        getOptionLabel={(option: any) => option?.display_name ?? ''}
        onChange={(event: any, value: any) => handleChange(event, value)}
        onInputChange={handleInputChange}
        renderTags={(value, getTagProps) =>
          value.map((option: any, index) => (
            <Chip
              variant="outlined"
              label={option?.display_name ?? ''}
              size="small"
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} placeholder={label}
            InputProps={{
               ...params.InputProps,
               startAdornment: (
                 <>
                  <InputAdornment position="start">
                    <GroupIcon className="person-icon" />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                 </>
               ),
            }}
          />
        )}

        renderOption={(props, option: any) => (
          <li {...props} key={option.id}>
            {option.display_name ?? ''}
          </li>
        )}

        popupIcon={
          <ExpandMoreIcon />
        }
        forcePopupIcon={filterContext[actionObject]?.length === 0}
        clearIcon={
          <CancelRounded className='cancel-icon' />
        }
        textcolor={textColor}
        weight={fontweight}
        multiple
      />
    </Box>
  );
}

export default MultiPlayerFilter;
