import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import axios from 'axios';
import StarOutlineRouted from '@mui/icons-material/StarOutlineRounded';
import StarRounded from '@mui/icons-material/StarRounded';
import API_URLS from '../../../api/api_urls';

function FavoriteButton() {
  const { searchId } = useParams();

  const [searchFavorite, setSearchFavorite] = useState(false);

  const saveSearch = () => {
    const currentStatus = searchFavorite;
    setSearchFavorite(!searchFavorite);
    axios.patch(`${API_URLS.search}/${searchId}/`, {
      id: searchId,
      favorite: !searchFavorite,
    }).then(() => {
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
      setSearchFavorite(currentStatus);
    });
  };

  const color = searchFavorite ? '#175CD3' : '#667085';
  const text = searchFavorite ? 'Saved' : 'Save';
  const buttonIcon = searchFavorite ? <StarRounded sx={{ color }} /> : <StarOutlineRouted sx={{ color }} />

  return (
    <Button
      variant="outlined"
      startIcon={buttonIcon}
      onClick={saveSearch}
      sx={{ marginRight: 3, color, borderColor: color }}
      disabled={searchId?.length === 0}
    >
      { text }
    </Button>
  );
}

export default FavoriteButton;
