import React from 'react';
import { Box, styled, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

const StyledTableCell = styled(TableCell)({
  padding: 4,
});

const StyledTableHeaderCell = styled(TableCell)({
  padding: 4,
  fontWeight: 650,
});

const StyledTotalCell = styled(TableCell)({
  padding: 4,
  fontWeight: 650,
});

interface ITeamProps {
  teamName: string;
  teamRecord: string;
  teamScore: number;
  teamLogoUrl?: string;
  teamWin: boolean;
  firstHalfScore: number;
  secondHalfScore: number;
  overtimeScore?: number;
};

interface IProps {
  home: ITeamProps,
  visitor: ITeamProps,
  numberOfOvertimes: number,
};

// visiting team is always displayed on top
function BoxScoreSplitStats({
  home,
  visitor,
  numberOfOvertimes,
}: IProps) {
  const hasOvertime = numberOfOvertimes > 0;
  return (
    <Box
      paddingX={10}
      display='flex'
      height='100%'
      justifyContent='center'
      justifyItems='center'
      alignItems='center'
      alignContent='center'
    >
      <Table aria-label="simple table" padding='none'>
        <TableHead>
          <TableRow>
            <StyledTableHeaderCell />
            <StyledTableHeaderCell align="center">1</StyledTableHeaderCell>
            <StyledTableHeaderCell align="center">2</StyledTableHeaderCell>
            { (hasOvertime) &&
              <StyledTableHeaderCell align="center">OT</StyledTableHeaderCell>
            }
            <StyledTableHeaderCell align="center">T</StyledTableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            key={1}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <StyledTableCell component="th" scope="row">
              { visitor.teamName }
            </StyledTableCell>
            <StyledTableCell align="center">{ visitor.firstHalfScore }</StyledTableCell>
            <StyledTableCell align="center">{ visitor.secondHalfScore }</StyledTableCell>
            { (hasOvertime) &&
              <StyledTableCell align="center">{ visitor.overtimeScore || 0 }</StyledTableCell>
            }
            <StyledTotalCell align="center">{ visitor.teamScore }</StyledTotalCell>
          </TableRow>
          <TableRow
            key={2}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <StyledTableCell component="th" scope="row">
              { home.teamName }
            </StyledTableCell>
            <StyledTableCell align="center">{ home.firstHalfScore }</StyledTableCell>
            <StyledTableCell align="center">{ home.secondHalfScore }</StyledTableCell>
            { (hasOvertime) &&
              <StyledTableCell align="center">{ home.overtimeScore || 0 }</StyledTableCell>
            }
            <StyledTotalCell align="center">{ home.teamScore }</StyledTotalCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}

export default BoxScoreSplitStats;
