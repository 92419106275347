import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import TeamGameTable from '../../../compounds/TeamGameTable/TeamGameTable';
import EliasBackButton from '../../../atoms/EliasBackButton/EliasBackButton';
import ROUTER_URLS from '../../../utils/router/urls';
import ResultsLoading from '../../../compounds/ResultsLoading/ResultsLoading';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import { fetchNcaaMbbTeamGameStats } from '../../../../api/ncaambb';
import { statsFilters, PAGE_TITLE, PAGE_SUBTITLE } from './constants';
import EliasStatsFilter from '../../../compounds/EliasStatsFilter/EliasStatsFilter';
import API_URLS from '../../../../api/api_urls';
import ModalFilters from '../../../compounds/ModalFilters/ModalFilters';
import { gameModalFilterCategories } from '../constants';
import { ContextFilterType } from '../../../types/context';
import NcaaMbbTeamFiltersLayout from '../../../compounds/ncaambb/NcaaMbbTeamFiltersLayout';
import { mapTeamRows } from '../../../utils/ncaambb/game-finder/map-results';
import { mapTeamColumns } from '../../../utils/ncaambb/game-finder/map-columns';
import withResultsLayout, { WithResultsLayoutProps } from '../../../compounds/ResultsWrapper/ResultsWrapper';
import TeamGameGroupBy from '../../../compounds/TeamGameGroupBy/TeamGameGroupBy';
import FinderStackLayout from '../../../compounds/FinderStackLayout/FinderStackLayout';
import { appendContextFilters } from '../../../utils/ncaambb/search';
import Header from '../../../compounds/Common/Header';
import EXTERNAL_URLS from '../../../utils/router/external_urls';

interface IProps extends WithResultsLayoutProps {};

function TeamGameFinderResults({
  columnHeaders,
  resultsData,
  tableDataLoading,
  filtersLoading,
  error,
  handleDone,
  pageOrSortDataFetch
}: IProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const pageSpecificSearchEnabled = (_context: ContextFilterType): boolean => true;

  const statsFilterPartial = <EliasStatsFilter statsFilterOptions={statsFilters} displayYearInfo={false} />;
  const groupByPartial = <TeamGameGroupBy />
  const topModalPartial = (
    <ModalFilters
      buttonTitle='Game Type & Date Filters'
      filters={gameModalFilterCategories}
      filterType='gameTypeFilters'
    />
  );

  return (
    <Dashboard loading={filtersLoading}>
      <FinderStackLayout
        header={
          <Header
            title={PAGE_TITLE}
            subtitle={PAGE_SUBTITLE}
            backButton={
              <EliasBackButton route={ROUTER_URLS.ncaambb.teamGameFinderSearch} />
            }
          />
        }
        topPanel={
          <NcaaMbbTeamFiltersLayout
            handleDone={handleDone}
            statsFilterPartial={statsFilterPartial}
            pageSpecificSearchEnabled={pageSpecificSearchEnabled}
            apiUrls={API_URLS.ncaambb}
            topModalPartial={topModalPartial}
            groupByPartial={groupByPartial}
          />
        }
        bottomPanel={
          tableDataLoading || error ?
          <ResultsLoading error={error} /> :
          <TeamGameTable
            data={resultsData?.results ?? []}
            dataCount={resultsData?.count ?? 0}
            dataMetaInfo={resultsData?.query?.back_to ?? []}
            columnData={columnHeaders || []}
            handleDone={pageOrSortDataFetch}
            linkUrls={EXTERNAL_URLS.NCAAM}
            explanationMessage={
              <Box sx={{ typography: 'body1' }}>
                <Link
                  component={RouterLink}
                  to={ROUTER_URLS.ncaambb.teamDataInfo}
                >
                  See how far your search went back in time.
                </Link>
              </Box>
            }
          />
        }
      />
    </Dashboard>
  );
}

export default withResultsLayout(
  TeamGameFinderResults,
  ROUTER_URLS.ncaambb.teamGameFinderResults,
  statsFilters,
  fetchNcaaMbbTeamGameStats,
  mapTeamColumns,
  mapTeamRows,
  appendContextFilters,
);
