import React from 'react';
import { Box, Container, Grid, Paper, Stack, TableCell, TableFooter, TablePagination, TableRow } from '@mui/material';
import { useAuthUser } from 'react-auth-kit';
import Header from '../../../compounds/Common/Header';
import StyledTable from '../../../atoms/StyledTable/StyledTable';
import PillButtonGroup from '../../../atoms/PillButtonGroup/PillButtonGroup';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import { useFetchRecentSearches } from '../../../hooks/useFetch';
import { RecentSearchType } from '../../../../api/types';
import ActionsCell from '../../../compounds/RecentSearch/ActionsCell';

const PAGE_TITLE = "Dashboard";
const PAGE_SUBTITLE = 'Welcome to your AccessElias dashboard. Dive right in and pick up where you left off.';

const RECENT_SAVED_HEADERS = [
  'Finder Type',
  'Query',
  'created',
  'actions',
];

const RECENT_SAVED_DATA_MAP = {
  'Finder Type': 'finderType',
  'Query': 'query',
  'created': 'timestamp',
  'actions': 'actions',
};

function FinderTypeCell({ row }: { row: RecentSearchType}) {
  const finderText = row.finder?.replaceAll('-', ' ').replace(/Finder/i, '');
  const leagueText = row.league;
  return (
    <Box sx={{ textTransform: 'capitalize'}}>
      {leagueText} {finderText}
    </Box>
  );
}

const mapData = (data: RecentSearchType[]) =>
  data.map((row) => ({
    id: row.id,
    finderType: <FinderTypeCell row={row} />,
    query: row.description,
    actions: <ActionsCell row={row} />,
    timestamp: row.human_timestamp,
  }));

function UserDashboard() {
  const auth = useAuthUser();
  const user = auth();

  const [recentSavedTab, setRecentSavedTab] = React.useState(0);
  const [page, setPage] = React.useState(0);

  const handleChangeRecentSaved = (newValue: number) => {
    setPage(0);
    setRecentSavedTab(newValue);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const { data, isLoading, isFetching } = useFetchRecentSearches(recentSavedTab === 1, page);

  if (!data) {
    return <Dashboard loading />;
  }

  return (
    <Dashboard loading={isLoading || isFetching}>
      <Container component={Stack} my={6} spacing={3}>
        <Header title={`${user?.firstName ?? 'User'}'s ${PAGE_TITLE}`} subtitle={PAGE_SUBTITLE} />
        <Paper elevation={3}>
          <Grid container mt={5} marginRight={5}>
            <Grid item xs={12}>
              <StyledTable
                uniqueRowKey='id'
                headers={RECENT_SAVED_HEADERS}
                data={mapData(data.results)}
                dataMap={RECENT_SAVED_DATA_MAP}
                size='small'
                labelHeaderRow={
                  <TableRow>
                    <TableCell colSpan={14}>
                      <h5 style={{ display: 'inline-block', fontSize: '1.4em', marginRight: 3, marginTop: 20, marginBottom: 20, }}>Your Queries</h5>
                      <PillButtonGroup selected={recentSavedTab} values={['Recent', 'Saved']} onChange={handleChangeRecentSaved} disabled={false} />
                    </TableCell>
                  </TableRow>
                }
                tableFooter={
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[]}
                        colSpan={4}
                        count={data.count}
                        rowsPerPage={15}
                        page={page}
                        sx={{
                          '.MuiTablePagination-actions': {
                            paddingRight: 5,
                          }
                        }}
                        onPageChange={handleChangePage}
                      />
                    </TableRow>
                  </TableFooter>
                }
              />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Dashboard>
  );
}

export default UserDashboard;
