import { Grid, TableCell, TableRow } from '@mui/material';
import React from 'react';
import StyledTable from '../../atoms/StyledTable/StyledTable';

const statsHeaders = [
  'Player',
  // 'GS',
  'MIN',
  'FG',
  'FGA',
  '3P',
  '3PA',
  'FT',
  'FTA',
  'OREB',
  'DREB',
  'REB',
  'AST',
  'STL',
  'BLK',
  'TO',
  'PF',
  'PTS',
];

const statsMap = {
  'Player': 'playerLink',
  // 'GS': 'GS',
  'MIN': 'minutes',
  'FG': 'fieldGoals',
  'FGA': 'fieldGoalAttempts',
  '3P': 'threePointFgs',
  '3PA': 'threePointFgas',
  'FT': 'freeThrows',
  'FTA': 'freeThrowAttempts',
  'OREB': 'offensiveRebounds',
  'DREB': 'defensiveRebounds',
  'REB': 'rebounds',
  'AST': 'assists',
  'STL': 'steals',
  'BLK': 'blocks',
  'TO': 'turnovers',
  'PF': 'personalFouls',
  'PTS': 'points',
};

interface IPlayerGameData {
  playerId: number;
  playerName: string;
  playerLink: JSX.Element;
  minutes: number;
  fieldGoals: number;
  fieldGoalAttempts: number;
  threePointFgs: number;
  threePointFgas: number;
  freeThrows: number;
  freeThrowAttempts: number;
  offensiveRebounds: number;
  defensiveRebounds: number;
  rebounds: number;
  assists: number;
  steals: number;
  blocks: number;
  turnovers: number;
  personalFouls: number;
  points: number;
};

interface IProps {
  teamName: string;
  coachName: string;
  data: IPlayerGameData[];
  teamLogoUrl?: string;
}

function GamePlayerStatsTable({ teamName, coachName, teamLogoUrl, data }: IProps) {
  return (
    <Grid container mt={5}>
      <StyledTable
        uniqueRowKey='playerId'
        headers={statsHeaders}
        data={data}
        dataMap={statsMap}
        labelHeaderRow={
          <TableRow>
            <TableCell colSpan={12}>
              <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {teamLogoUrl && <img src={teamLogoUrl} height="50" width="50" alt="team-logo" /> }
                <h5 style={{ fontSize: '1.4em', margin: 0, }}>{ teamName }</h5>
              </span>
            </TableCell>
            <TableCell colSpan={6} align='right'>
              <span style={{ fontSize: '1.25em', margin: 0, fontWeight: 600, marginRight: 20, }}>Head Coach</span>
              <span style={{ fontSize: '1.15em', margin: 0, }}>{ coachName }</span>
            </TableCell>
          </TableRow>
        }
        headerCellStyles={{
          padding: '4px',
          fontSize: '12px',
        }}
        tableCellStyles={{
          padding: '4px',
          fontSize: '12px',
        }}
      />
    </Grid>
  );
};

export default GamePlayerStatsTable;