import {
  StatFilter,
  StatFilterType,
} from '../../../compounds/EliasStatsFilter/filter-stats-utils';

export type FinderSelectOptionType = {
  id: string;
  label: string;
};

export const PAGE_TITLE = 'NBA Team Span Finder';
export const PAGE_SUBTITLE =
  'Search the most complete and accurate NBA database for team game stats, that occurred within a span of games.';

export const spanRangeOptions: FinderSelectOptionType[] = [
  { id: 'none', label: 'Any' },
  { id: 'single-season', label: 'Single Season' },
  { id: 'current', label: 'Current' },
  { id: 'current-single-season', label: 'Current (single season only)' },
  { id: 'start-career', label: 'Start of Career' },
  { id: 'start-season', label: 'Start of Season' },
];

export const DEFAULT_SPAN_RANGE_OPTION = spanRangeOptions[0].id;

export const statsFilters: StatFilter[] = [
  {
    id: 'franchise_score',
    abbr: 'TM Score',
    name: 'Team Score',
    year: 1946,
    type: StatFilterType.equality,
  },
  {
    id: 'opponent_score',
    abbr: 'OPP Score',
    name: 'Opponent Score',
    year: 1946,
    type: StatFilterType.equality,
  },
  {
    id: 'points_difference',
    abbr: 'SC Diff',
    name: 'Score Difference',
    year: 1946,
    type: StatFilterType.equality,
  },
  {
    id: 'points',
    abbr: 'PTS',
    name: 'Points',
    year: 1946,
    type: StatFilterType.equality,
  },
  {
    id: 'points_per_game',
    abbr: 'PTS/G',
    name: 'Points Per Game',
    year: 1946,
    type: StatFilterType.equality,
    searchModifier: (value: string, modifierValue: number) => {
      const result = Number(value) * modifierValue;
      return result.toString();
    },
    conflictingStatIds: ['points'],
  },
  {
    id: 'total_rebounds',
    abbr: 'REB',
    name: 'Rebounds',
    year: 1950,
    type: StatFilterType.equality,
  },
  {
    id: 'total_rebounds_per_game',
    abbr: 'REB/G',
    name: 'Rebounds Per Game',
    year: 1950,
    type: StatFilterType.equality,
    searchModifier: (value: string, modifierValue: number) => {
      const result = Number(value) * modifierValue;
      return result.toString();
    },
    conflictingStatIds: ['total_rebounds'],
  },
  {
    id: 'assists',
    abbr: 'AST',
    name: 'Assists',
    year: 1950,
    type: StatFilterType.equality,
  },
  {
    id: 'assists_per_game',
    abbr: 'AST/G',
    name: 'Assists Per Game',
    year: 1950,
    type: StatFilterType.equality,
    searchModifier: (value: string, modifierValue: number) => {
      const result = Number(value) * modifierValue;
      return result.toString();
    },
    conflictingStatIds: ['assists'],
  },
  {
    id: 'steals',
    abbr: 'STL',
    name: 'Steals',
    year: 1973,
    type: StatFilterType.equality,
  },
  {
    id: 'blocks',
    abbr: 'BLK',
    name: 'Blocks',
    year: 1973,
    type: StatFilterType.equality,
  },
  {
    id: 'field_goals_made',
    abbr: 'FG',
    name: 'Field Goals',
    year: 1946,
    type: StatFilterType.equality,
  },
  {
    id: 'field_goal_attempts',
    abbr: 'FGA',
    name: 'Field Goal Attempts',
    year: 1956,
    type: StatFilterType.equality,
  },
  {
    id: 'field_goal_percentage',
    abbr: 'FG%',
    name: 'Field Goal %',
    year: 1956,
    type: StatFilterType.equality,
    explanation: '.xxx format',
  },  
  {
    id: 'free_throws_made',
    abbr: 'FT',
    name: 'Free Throws',
    year: 1946,
    type: StatFilterType.equality,
  },
  {
    id: 'free_throw_attempts',
    abbr: 'FTA',
    name: 'Free Throw Attempts',
    year: 1956,
    type: StatFilterType.equality,
  },
  {
    id: 'free_throw_percentage',
    abbr: 'FT%',
    name: 'Free Throw %',
    year: 1956,
    type: StatFilterType.equality,
    explanation: '.xxx format',
  },
  {
    id: 'three_point_field_goals_made',
    abbr: 'FG3M',
    name: 'Three Point Field Goals',
    year: 1979,
    type: StatFilterType.equality,
  },
  {
    id: 'three_point_field_goal_attempts',
    abbr: 'FG3A',
    name: 'Three Point FG Attempts',
    year: 1979,
    type: StatFilterType.equality,
  },
  {
    id: 'three_point_field_goal_percentage',
    abbr: 'FG3%',
    name: 'Three Point FG %',
    year: 1979,
    type: StatFilterType.equality,
    explanation: '.xxx format',
  },
  {
    id: 'turnovers',
    abbr: 'TO',
    name: 'Turnovers',
    year: 1977,
    type: StatFilterType.equality,
  },
  {
    id: 'personal_fouls',
    abbr: 'PF',
    name: 'Personal Fouls',
    year: 1950,
    type: StatFilterType.equality,
  },
  {
    id: 'offensive_rebounds',
    abbr: 'OREB',
    name: 'Offensive Rebounds',
    year: 1973,
    type: StatFilterType.equality,
  },
  {
    id: 'defensive_rebounds',
    abbr: 'DREB',
    name: 'Defensive Rebounds',
    year: 1973,
    type: StatFilterType.equality,
  },
  {
    id: 'last_game_result',
    abbr: 'WLG',
    name: 'Won Last Game',
    year: 1946,
    type: StatFilterType.boolean,
    searchModifier: (value: string) => {
      const result = value === '1' ? 'W' : 'L';
      return result;
    },
  },
  {
    id: 'season_game_number',
    abbr: 'TGN',
    name: 'Team G Num of Seas',
    year: 1946,
    type: StatFilterType.equality,
  },
  {
    id: 'franchise_win_percentage',
    abbr: 'PCT',
    name: 'Team Win PCT ent. game',
    year: 1946,
    type: StatFilterType.equality,
    explanation: '.xxx format',
  },
  // TODO: JN, 20230814, disabled for the time being
  // {
  //   id: 'seconds',
  //   abbr: 'MIN',
  //   name: 'Minutes',
  //   year: 1955,
  //   type: StatFilterType.equality,
  // },
  {
    id: 'points_off_turnovers',
    abbr: 'PTO',
    name: 'Points off Turnovers',
    year: 1997,
    type: StatFilterType.equality,
  },
  {
    id: 'dunks',
    abbr: 'DKS',
    name: 'Dunks',
    year: 1997,
    type: StatFilterType.equality,
  },
  {
    id: 'second_chance_points',
    abbr: 'PTS2',
    name: 'Second Chance Points',
    year: 1997,
    type: StatFilterType.equality,
  },
  {
    id: 'points_in_paint',
    abbr: 'PPTS',
    name: 'Points in Paint',
    year: 1997,
    type: StatFilterType.equality,
  },
  {
    id: 'opponent_win_percentage',
    abbr: 'OPCT',
    name: 'Opp Win PCT ent. game',
    year: 1946,
    type: StatFilterType.equality,
    explanation: '.xxx format',
  },
  {
    id: 'win_percentage_difference',
    abbr: 'PDIFF',
    name: 'Win PCT Differential',
    year: 1946,
    type: StatFilterType.equality,
    explanation: '.xxx format',
  },
  {
    id: 'field_goals_difference',
    abbr: 'FGDIFF',
    name: 'Field Goals Difference',
    year: 1946,
    type: StatFilterType.equality,
  },
  {
    id: 'blocks_difference',
    abbr: 'BLKDIFF',
    name: 'Blocks Difference',
    year: 1973,
    type: StatFilterType.equality,
  },
  {
    id: 'free_throws_difference',
    abbr: 'FTDIFF',
    name: 'Free Throws Difference',
    year: 1946,
    type: StatFilterType.equality,
  },
  {
    id: 'three_point_field_goals_difference',
    abbr: 'FG3DIFF',
    name: 'Three Point Field Goals Difference',
    year: 1979,
    type: StatFilterType.equality,
  },
  {
    id: 'total_rebounds_difference',
    abbr: 'REBDIFF',
    name: 'Rebounds Difference',
    year: 1950,
    type: StatFilterType.equality,
  },
  {
    id: 'personal_fouls_difference',
    abbr: 'PFDIFF',
    name: 'Personal Fouls Difference',
    year: 1950,
    type: StatFilterType.equality,
  },
  {
    id: 'turnovers_difference',
    abbr: 'TODIFF',
    name: 'Turnovers Difference',
    year: 1977,
    type: StatFilterType.equality,
  },
  {
    id: 'steals_difference',
    abbr: 'STLDIFF',
    name: 'Steals Differnce',
    year: 1973,
    type: StatFilterType.equality,
  },
  {
    id: 'field_goal_percentage_difference',
    abbr: 'FG% DIFF',
    name: 'Field Goal Percentage Difference',
    year: 1956,
    type: StatFilterType.equality,
    explanation: '.xxx format',
  },
];
