import React from 'react';
import axios from 'axios';
import { generatePath, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useSignOut } from 'react-auth-kit';
import API_URLS from '../../../api/api_urls';
import { useFilterContext, useFilterDispatchContext } from '../../contexts/FilterContext';
import { StatFilter } from '../EliasStatsFilter/filter-stats-utils';
import { fetchSearch } from '../../../api/api';
import { SelectedStatFilterType } from '../../types/statsFilter';
import { ColumnMappingType } from '../../types/results';
import { ContextFilterType } from '../../types/context';

export interface WithResultsLayoutProps {
  columnHeaders: any;
  resultsData: any;
  tableDataLoading: boolean;
  filtersLoading: boolean;
  error?: Error | null;
  handleDone: () => void;
  pageOrSortDataFetch: (sortByParam: string, pageParam: number) => void;
};

function withResultsLayout<T>(
  WrappedComponent: React.ComponentType<T & WithResultsLayoutProps>,
  navigateTo: string,
  statsFilters: StatFilter[],
  fetchData: (
    filters: Array<string>,
    signOut: () => boolean,
    page: number,
    sortBy: string,
    uuid?: string,) => Promise<any>,
  mapColumns: (
    groupByColumn: string,
    localFilters: SelectedStatFilterType[],
    filterContext: ContextFilterType,
  ) => ColumnMappingType[],
  mapRows: (values: any, groupByColumn: string, page: number) => any,
  appendContextFilters: (
    localFilters: string[],
    context: ContextFilterType,
    statsFilterOptions: StatFilter[],
    searchModifierValue?: string | undefined,
  ) => string[]
) {

  function ComponentWithProps(props: T) {
    const navigate = useNavigate();
    const filterContext: any = useFilterContext();
    const filterDispatchContext: any = useFilterDispatchContext();
    const location = useLocation();
    const signOut = useSignOut();
    const { searchId } = useParams();
    const [searchParams] = useSearchParams();

    const { loadFiltersFromState } = location.state ?? { loadFiltersFromState: false };

    const [columnHeaders, setColumnHeaders] = React.useState<any>();
    const [resultsData, setResultsData] = React.useState<any>();
    const [tableDataLoading, setTableDataLoading] = React.useState(false);
    const [filtersLoading, setFiltersLoading] = React.useState(false);
    const [error, setError] = React.useState<Error | null>();

    const searchSuccessNavigate = (id: string, path: string) => {
      const route = generatePath(path, { searchId: id });
      navigate(route, {
        state: { loadFiltersFromState: true },
        replace: true,
      });
    };

    // updating the context happens async but there isnt a great way to listen or await changes
    // there. get around this but passing the context object directly
    const saveSearchAndNavigate = (
      passedFilterContext: any,
      queryString: string,
    ) => {
      axios.post(API_URLS.search, {
        context: {
          passedFilterContext,
        },
        query_string: queryString,
      }).then((response) => {
        const newId = response.data.id;
        searchSuccessNavigate(newId, navigateTo);
      }).catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
    };

    const handleDone = () => {
      filterDispatchContext({
        type: 'pageByChanged',
        page: 0,
      });
      filterDispatchContext({
        type: 'sortByChanged',
        sortBy: '-game__date',
      });
      filterDispatchContext({
        type: 'changeSearchStatus',
        searchEnabled: false,
      });
      const filters: string[] = [
        `ordering=${filterContext.sortBy}`,
      ];
      const combinedFilters = appendContextFilters(filters, filterContext, statsFilters, filterContext.spanLength);
      const queryString = combinedFilters.join('&')
      saveSearchAndNavigate(filterContext, queryString);
    };

    const fetchAndMapData = ({
      sortBy,
      page,
      uuid,
      searchFilters,
      groupBy,
    }: {
      sortBy: string,
      page: number,
      uuid?: string,
      searchFilters?: string[],
      groupBy: string,
    }) => {
      const filters: string[] = searchFilters || [];
      const combinedFilters = appendContextFilters(filters, filterContext, statsFilters, filterContext.spanLength);
  
      setError(null);
      setTableDataLoading(true);
      fetchData(
        combinedFilters,
        signOut,
        page,
        sortBy,
        uuid,
      ).then((data: any) => {
        const rows = mapRows(data.results, groupBy, page);
        const mappedData = {
          ...data,
          results: rows,
        }
        setResultsData(mappedData);
        setTableDataLoading(false);
        setError(null);
      }).catch((responseError) => {
        setTableDataLoading(false);
        setError(responseError)
        filterDispatchContext({
          type: 'changeSearchStatus',
          searchEnabled: true,
        });
      });
    };

    const fetchFilterContext = (id: string) => {
      setFiltersLoading(true);
      fetchSearch(id).then((data: any) => {
        const filtersFromApi = data.context;
        const apiFiltersContext = filtersFromApi.passedFilterContext;
        filterDispatchContext({
          type: 'loadFilters',
          apiFilters: apiFiltersContext,
        });
        const columns = mapColumns(
          apiFiltersContext.groupBy,
          apiFiltersContext.selectedStatsFilters,
          apiFiltersContext,
        );
        setColumnHeaders(columns || []);
        fetchAndMapData({
          sortBy: apiFiltersContext.sortBy,
          page: apiFiltersContext.page,
          uuid: id,
          groupBy: apiFiltersContext.groupBy,
        });
        setFiltersLoading(false);
      })
    };
  
    const pageOrSortDataFetch = (sortByParam: string, pageParam: number) => {
      const columns = mapColumns(
        filterContext.groupBy,
        filterContext.selectedStatsFilters,
        filterContext,
      );
      setColumnHeaders(columns);
      fetchAndMapData({
        sortBy: sortByParam,
        page: pageParam,
        groupBy: filterContext.groupBy,
      });
    };

    const mapSearchParamsToArray = (): string[] => {
      const params: string[] = [];
  
      const playerId = searchParams.get("playerId");
      const playerName = searchParams.get("playerName");
      if (playerId && playerId.length > 0) {
        params.push(`player__id=${playerId}`);
  
        if (playerName && playerName.length > 0) {
          filterDispatchContext({
            type: 'changePlayerObject',
            playerObject: { id: playerId, display_name: playerName },
            searchEnabled: true,
          });
        }
      }
      const teamId = searchParams.get("teamId");
      if (teamId && teamId.length > 0) {
        params.push(`player__id=${teamId}`);
      }
      const gameId = searchParams.get("gameId");
      if (gameId && gameId.length > 0) {
        params.push(`player__id=${gameId}`);
      }
  
      return params;
    };
  
    React.useEffect(() => {
      if (searchId && searchId.length > 0 && (!loadFiltersFromState)) {
        fetchFilterContext(searchId);
      } else {
        const columns = mapColumns(
          filterContext.groupBy,
          filterContext.selectedStatsFilters,
          filterContext,
        );
        setColumnHeaders(columns || []);
        const params = mapSearchParamsToArray();
        fetchAndMapData({
          sortBy: filterContext.sortBy,
          page: filterContext.page,
          uuid: searchId,
          searchFilters: params,
          groupBy: filterContext.groupBy,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchId]);

    return (
      <WrappedComponent
        { ...props }
        handleDone={handleDone}
        columnHeaders={columnHeaders}
        resultsData={resultsData}
        tableDataLoading={tableDataLoading}
        filtersLoading={filtersLoading}
        error={error}
        pageOrSortDataFetch={pageOrSortDataFetch}
      />
    )
  };
  return ComponentWithProps;
};

export default withResultsLayout;
