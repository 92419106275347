import { StatFilter, StatFilterType } from "../../../compounds/EliasStatsFilter/filter-stats-utils";

export type FinderSelectOptionType = {
  id: string;
  label: string;
};

export const PAGE_TITLE = "NCAA Men's Basketball Player Game Finder";
export const PAGE_SUBTITLE =
  'Search the most complete and accurate NCAA MBB database for player game stats';

export const groupByOptions: FinderSelectOptionType[] = [
  { id: 'none', label: 'None' },
  { id: 'player', label: 'Player career instances' },
  { id: 'player-season', label: 'Player within a season' },
  { id: 'player-franchise', label: 'Player with a school' },
  { id: 'player-opponent', label: 'Player against an opponent' },
  // { id: 'player-franchise-season', label: 'Player with a franchise in a season' },
  // { id: 'player-opponent-season', label: 'Player against an opponent in a season' },
];

export const DEFAULT_GROUP_BY_STATUS = groupByOptions[0].id;

export const statsFilters: StatFilter[] = [
  {
    id: 'points',
    abbr: 'PTS',
    name: 'Points',
    type: StatFilterType.equality,
  },
  {
    id: 'total_rebounds',
    abbr: 'REB',
    name: 'Rebounds',
    type: StatFilterType.equality,
  },
  {
    id: 'assists',
    abbr: 'AST',
    name: 'Assists',
    type: StatFilterType.equality,
  },
  {
    id: 'steals',
    abbr: 'STL',
    name: 'Steals',
    type: StatFilterType.equality,
  },
  {
    id: 'blocks',
    abbr: 'BLK',
    name: 'Blocks',
    type: StatFilterType.equality,
  },
  {
    id: 'double_double',
    abbr: 'DDBL',
    name: 'Double Double',
    type: StatFilterType.boolean,
  },
  {
    id: 'triple_double',
    abbr: 'TDBL',
    name: 'Triple Double',
    type: StatFilterType.boolean,
  },
  {
    id: 'field_goals_made',
    abbr: 'FG',
    name: 'Field Goals',
    type: StatFilterType.equality,
  },
  {
    id: 'field_goal_attempts',
    abbr: 'FGA',
    name: 'Field Goal Attempts',
    type: StatFilterType.equality,
  },
  {
    id: 'field_goal_percentage',
    abbr: 'FG%',
    name: 'Field Goal %',
    type: StatFilterType.equality,
    explanation: '.xxx format',
  },
  {
    id: 'free_throws_made',
    abbr: 'FT',
    name: 'Free Throws',
    type: StatFilterType.equality,
  },
  {
    id: 'free_throw_attempts',
    abbr: 'FTA',
    name: 'Free Throw Attempts',
    type: StatFilterType.equality,
  },
  {
    id: 'free_throw_percentage',
    abbr: 'FT%',
    name: 'Free Throw %',
    type: StatFilterType.equality,
    explanation: '.xxx format',
  },
  {
    id: 'three_point_field_goals_made',
    abbr: 'FG3M',
    name: 'Three Point Field Goals',
    type: StatFilterType.equality,
  },
  {
    id: 'three_point_field_goal_attempts',
    abbr: 'FG3A',
    name: 'Three Point FG Attempts',
    type: StatFilterType.equality,
  },
  {
    id: 'three_point_field_goal_percentage',
    abbr: 'FG3%',
    name: 'Three Point FG %',
    type: StatFilterType.equality,
    explanation: '.xxx format',
  },
  {
    id: 'turnovers',
    abbr: 'TO',
    name: 'Turnovers',
    year: 1977,
    type: StatFilterType.equality,
  },
  {
    id: 'personal_fouls',
    abbr: 'PF',
    name: 'Personal Fouls',
    year: 1950,
    type: StatFilterType.equality,
  },
  {
    id: 'minutes',
    abbr: 'MIN',
    name: 'Minutes',
    year: 1955,
    type: StatFilterType.equality,
  },
  {
    id: 'offensive_rebounds',
    abbr: 'OREB',
    name: 'Offensive Rebounds',
    year: 1973,
    type: StatFilterType.equality,
  },
  {
    id: 'defensive_rebounds',
    abbr: 'DREB',
    name: 'Defensive Rebounds',
    year: 1973,
    type: StatFilterType.equality,
  },
  {
    id: 'disqualified',
    abbr: 'DQ',
    name: 'Disqualified',
    year: 1950,
    type: StatFilterType.boolean,
  },
  {
    id: 'franchise_score',
    abbr: 'TM Score',
    name: 'Team Score',
    year: 1946,
    type: StatFilterType.equality,
  },
  {
    id: 'opponent_score',
    abbr: 'OPP Score',
    name: 'Opponent Score',
    year: 1946,
    type: StatFilterType.equality,
  },
  {
    id: 'score_difference',
    abbr: 'SC Diff',
    name: 'Score Difference',
    year: 1946,
    type: StatFilterType.equality,
  },
  {
    id: 'opponent_rank',
    abbr: 'OPP Rank',
    name: 'Opponent Rank',
    year: 1946,
    type: StatFilterType.equality,
  },
  // {
  //   id: 'started',
  //   abbr: 'GS',
  //   name: 'Game Started',
  //   year: 1970,
  //   type: StatFilterType.boolean,
  //   explanation: '1=Game Started, 0=Off-the-Bench',
  // },
  // {
  //   id: 'win',
  //   abbr: 'Team Win',
  //   name: 'Team Win',
  //   year: 1946,
  //   type: StatFilterType.boolean,
  // },
];
