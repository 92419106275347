import React from 'react';
import { Stack, Typography } from '@mui/material';

interface IProps {
  date: string;
  venue: string;
  numberOfOvertimes: number;
}

function BoxScoreHeader({ date, venue, numberOfOvertimes }: IProps) {
  const overtimeText = numberOfOvertimes > 0 ? `/ OT${numberOfOvertimes}` : '';
  return (
    <Stack
      direction='row'
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Typography
        component="h4"
        variant="h6"
        fontWeight="600"
        padding={2}
      >
        { date } { overtimeText }
      </Typography>
      <Typography
        component="div"
        variant='body1'
        padding={2}
      >
        { venue }
      </Typography>
    </Stack>
  );
}

export default BoxScoreHeader;
