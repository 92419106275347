import { ColumnMappingType } from "../../types/results";

const DEFAULT_COL_WIDTH = 80;
const MEDIUM_COL_WIDTH = 145;
const WIDE_COL_WIDTH = 185; // fits longest player display name at 24 characters

type ResultColumnKeyType = 'playerDisplayName' |
                           'gameDate' |
                           'gameType' |
                           'result' |
                           'recordType' |
                           'gameStarted' |
                           'offensiveRebounds' |
                           'defensiveRebounds' |
                           'pointsInPaint' |
                           'pointsOffTurnovers' |
                           'dunks' |
                           'secondChancePoints' |
                           'tripleDouble' |
                           'disqualified' |
                           'personalFouls' |
                           'franchiseScore' |
                           'opponentScore' |
                           'scoreDifference' |
                           'lastGameResult' |
                           'seasonGameNumber' |
                           'franchiseWinPercentage' |
                           'opponentWinPercentage' |
                           'winPercentageDifference' |
                           'assistsDifference' |
                           'blocksDifference' |
                           'pointsDifference' |
                           'fieldGoalsDifference' |
                           'freeThrowsDifference' |
                           'jumpBallsWonDifference' |
                           'threePointFieldGoalsDifference' |
                           'totalReboundsDifference' |
                           'personalFoulsDifference' |
                           'turnoversDifference' |
                           'stealsDifference' |
                           'fieldGoalPercentageDifference' |
                           'plusMinus' |
                           'minutesDisplay' |
                           'points' |
                           'totalRebounds' |
                           'assists' |
                           'fieldGoalsMade' |
                           'fieldGoalAttempts' |
                           'fieldGoalPercentage' |
                           'threePointFieldGoalsMade' |
                           'threePointFieldGoalAttempts' |
                           'threePointFieldGoalPercentage' |
                           'freeThrowsMade' |
                           'freeThrowAttempts' |
                           'freeThrowPercentage' |
                           'steals' |
                           'blocks' |
                           'turnovers' |
                           'rank' |
                           'instancesCount' |
                           'lastInstance' |
                           'firstInstance' |
                           'season' |
                           'franchiseName' |
                           'opponentName' |
                           'firstOccurence' |
                           'lastOccurence' |
                           'startAge' |
                           'endAge' |
                           'gameDatesArray' |
                           'resultRow' |
                           'franchiseSeasonGameNumber' |
                           'opponentRank' |
                           'pointsFirstHalf' |
                           'opponentPointsFirstHalf' |
                           'pointsFirstHalfDifference' |
                           'pointsSecondHalf' |
                           'opponentPointsSecondHalf' |
                           'pointsSecondHalfDifference'
                           ;

export const RESULT_COLUMNS: { [key in ResultColumnKeyType]: ColumnMappingType } = {
  playerDisplayName: {
    headerName: 'Player',
    field: 'player__display_name',
    width: WIDE_COL_WIDTH,
    display: 'alwaysFirst',
  },
  gameDate: {
    headerName: 'Game Date',
    field: 'game__date',
    width: MEDIUM_COL_WIDTH,
    display: 'alwaysFirst',
  },
  gameType: {
    headerName: 'Game Type',
    field: 'game__game_class',
    width: 120,
    display: 'alwaysFirst',
  },
  result: {
    headerName: 'Result',
    field: 'result',
    width: WIDE_COL_WIDTH,
    display: 'alwaysFirst',
  },
  recordType: {
    headerName: 'Rec Type',
    field: 'record_type',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
  },
  gameStarted: {
    headerName: 'GS',
    field: 'started',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Game Started',
  },
  offensiveRebounds: {
    headerName: 'OREB',
    field: 'offensive_rebounds',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Offensive Rebounds',
  },
  defensiveRebounds: {
    headerName: 'DREB',
    field: 'defensive_rebounds',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Defensive Rebounds',
  },
  pointsInPaint: {
    headerName: 'PPTS',
    field: 'points_in_paint',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Points in Paint',
  },
  pointsOffTurnovers: {
    headerName: 'PTO',
    field: 'points_off_turnovers',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Points off Turnovers',
  },
  dunks: {
    headerName: 'DKS',
    field: 'dunks',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Dunks',
  },
  secondChancePoints: {
    headerName: 'PTS2',
    field: 'second_chance_points',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Second Change Points',
  },
  tripleDouble: {
    headerName: 'TDBL',
    field: 'triple_double',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Triple Double',
  },
  disqualified: {
    headerName: 'DQ',
    field: 'disqualified',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Disqualified',
  },
  personalFouls: {
    headerName: 'PF',
    field: 'personal_fouls',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Personal Fouls',
  },
  franchiseScore: {
    headerName: 'TM Score',
    field: 'franchise_score',
    width: DEFAULT_COL_WIDTH,
    display: 'whensSelected',
    description: 'Franchise Score',
  },
  opponentScore: {
    headerName: 'OPP Score',
    field: 'opponent_score',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Opponent Score',
  },
  scoreDifference: {
    headerName: 'SC Diff',
    field: 'score_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Score Difference',
  },
  lastGameResult: {
    headerName: 'WLG',
    field: 'last_game_result',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Last Game Result',
  },
  seasonGameNumber: {
    headerName: 'TGN',
    field: 'season_game_number',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Season Game Number',
  },
  franchiseWinPercentage: {
    headerName: 'PCT',
    field: 'franchise_win_percentage',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Franchise Win Percentage',
  },
  opponentWinPercentage: {
    headerName: 'OPCT',
    field: 'opponent_win_percentage',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Opponent Win Percentage',
  },
  assistsDifference: {
    headerName: 'AST Diff',
    field: 'assists_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Assists Difference',
  },
  blocksDifference: {
    headerName: 'BLK Diff',
    field: 'blocks_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Blocks Difference',
  },
  fieldGoalsDifference: {
    headerName: 'FGDIFF',
    field: 'field_goals_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Field Goals Difference',
  },
  fieldGoalPercentageDifference: {
    headerName: 'FG%DIFF',
    field: 'field_goal_percentage_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Field Goal % Difference',
  },
  freeThrowsDifference: {
    headerName: 'FTDIFF',
    field: 'free_throws_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Free Throws Difference',
  },
  jumpBallsWonDifference: {
    headerName: 'JBWDIFF',
    field: 'jump_balls_won_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Jump Balls Won Difference',
  },
  personalFoulsDifference: {
    headerName: 'PFDIFF',
    field: 'personal_fouls_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Personal Foulds Difference',
  },
  pointsDifference: {
    headerName: 'SC Diff',
    field: 'points_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Score Difference',
  },
  stealsDifference: {
    headerName: 'STLDIFF',
    field: 'steals_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Steals Difference',
  },
  threePointFieldGoalsDifference: {
    headerName: 'FG3DIFF',
    field: 'three_point_field_goals_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: '3 Points Field Goals Difference',
  },
  totalReboundsDifference: {
    headerName: 'REBDIFF',
    field: 'total_rebounds_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Total Rebounds Difference',
  },
  turnoversDifference: {
    headerName: 'TODIFF',
    field: 'turnovers_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Turnovers Difference',
  },
  winPercentageDifference: {
    headerName: 'PDIFF',
    field: 'win_percentage_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Win Percentage Difference',
  },
  plusMinus: {
    headerName: '(+/-)',
    field: 'plus_minus',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Plus Minus',
  },
  minutesDisplay: {
    headerName: 'MIN',
    field: 'minutes_display',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
    description: 'Minutes Played',
  },
  points: {
    headerName: 'PTS',
    field: 'points',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
    description: 'Points',
  },
  totalRebounds: {
    headerName: 'REB',
    field: 'total_rebounds',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
    description: 'Total Rebounds'
  },
  assists: {
    headerName: 'AST',
    field: 'assists',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
    description: 'Assists',
  },
  fieldGoalsMade: {
    headerName: 'FG',
    field: 'field_goals_made',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
    description: 'Field Goals Made',
  },
  fieldGoalAttempts: {
    headerName: 'FGA',
    field: 'field_goal_attempts',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
    description: 'Field Goal Attempts',
  },
  fieldGoalPercentage: {
    headerName: 'FG%',
    field: 'field_goal_percentage',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
    description: 'Field Goal %',
  },
  threePointFieldGoalsMade: {
    headerName: 'FG3M',
    field: 'three_point_field_goals_made',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
    description: '3 Point Field Goals Made',
  },
  threePointFieldGoalAttempts: {
    headerName: 'FG3A',
    field: 'three_point_field_goal_attempts',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
    description: '3 Point Field Goal Attempts',
  },
  threePointFieldGoalPercentage: {
    headerName: 'FG3%',
    field: 'three_point_field_goal_percentage',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
    description: '3 Point Field Goal %',
  },
  freeThrowsMade: {
    headerName: 'FT',
    field: 'free_throws_made',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
    description: 'Free Throws Made',
  },
  freeThrowAttempts: {
    headerName: 'FTA',
    field: 'free_throw_attempts',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
    description: 'Free Throw Attempts',
  },
  freeThrowPercentage: {
    headerName: 'FT%',
    field: 'free_throw_percentage',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
    description: 'Free Throw %',
  },
  steals: {
    headerName: 'STL',
    field: 'steals',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
    description: 'Steals',
  },
  blocks: {
    headerName: 'BLK',
    field: 'blocks',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
    description: 'Blocks',
  },
  turnovers: {
    headerName: 'TO',
    field: 'turnovers',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
    description: 'Turnovers',
  },
  rank: {
    headerName: 'Row',
    field: 'rank',
    width: DEFAULT_COL_WIDTH,
    sortable: false,
  },
  instancesCount: {
    headerName: 'Instances',
    field: 'count',
    width: MEDIUM_COL_WIDTH,
  },
  lastInstance: {
    headerName: 'Last Instance',
    field: 'last',
    width: WIDE_COL_WIDTH,
  },
  firstInstance: {
    headerName: 'First Instance',
    field: 'first',
    width: WIDE_COL_WIDTH,
  },
  season: {
    headerName: 'Season',
    field: 'season',
    width: WIDE_COL_WIDTH,
  },
  franchiseName: {
    headerName: 'Franchise',
    field: 'franchise_name',
    width: WIDE_COL_WIDTH,
  },
  opponentName: {
    headerName: 'Opponent',
    field: 'opponent_name',
    width: WIDE_COL_WIDTH,
  },
  firstOccurence: {
    headerName: 'First',
    field: 'first',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysFirst',
  },
  lastOccurence: {
    headerName: 'Last',
    field: 'last',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysFirst',
  },
  startAge: {
    headerName: 'Start Age',
    field: 'start_age',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysFirst',
  },
  endAge: {
    headerName: 'End Age',
    field: 'end_age',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysFirst',
  },
  gameDatesArray: {
    headerName: 'Games',
    field: 'games',
    width: WIDE_COL_WIDTH * 2,
    display: 'alwaysThird',
  },
  resultRow: {
    headerName: 'Row',
    field: 'rank',
    width: 40,
    sortable: false,
    display: 'alwaysFirst',
    align: 'center',
    headerAlign: 'center',
  },
  franchiseSeasonGameNumber: {
    headerName: 'TGN',
    field: 'franchise_season_game_number',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Team Game Number',
  },
  opponentRank: {
    headerName: 'OPPRANK',
    field: 'opponent_rank',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Opponent Rank',
  },
  pointsFirstHalf: {
    headerName: 'PTS H1',
    field: 'points_half_1',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Team First Half Points',
  },
  opponentPointsFirstHalf: {
    headerName: 'OPP PTS H1',
    field: 'opponent_points_half_1',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Opponent Team First Half Points',
  },
  pointsFirstHalfDifference: {
    headerName: 'SCDIFF H1',
    field: 'points_diff_half_1',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'First Half Score Difference',
  },
  pointsSecondHalf: {
    headerName: 'PTS H2',
    field: 'points_half_2',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Team Second Half Points',
  },
  opponentPointsSecondHalf: {
    headerName: 'OPP PTS H2',
    field: 'opponent_points_half_2',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Opponent Second Half Points',
  },
  pointsSecondHalfDifference: {
    headerName: 'SCDIFF H2',
    field: 'points_diff_half_2',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Second Half Score Difference',
  },
};


type PerGameResultColumnKeyType = 'points' |
                                  'totalRebounds' |
                                  'assists' |
                                  'blocks' |
                                  'steals' |
                                  'fieldGoalsMade' |
                                  'fieldGoalAttempts' |
                                  'threePointFieldGoalsMade' |
                                  'threePointFieldGoalAttempts' |
                                  'freeThrowsMade' |
                                  'freeThrowAttempts' |
                                  'offensiveRebounds' |
                                  'defensiveRebounds' |
                                  'pointsInPaint' |
                                  'pointsOffTurnovers' |
                                  'dunks' |
                                  'secondChancePoints' |
                                  'doubleDouble' |
                                  'tripleDouble' |
                                  'turnovers' |
  'plusMinus' |
  'franchiseScore' |
  'opponentScore' |
  'personalFouls'

export const PER_GAME_RESULT_COLUMNS: { [key in PerGameResultColumnKeyType]: ColumnMappingType } = {
  points: {
    headerName: 'PTS/G',
    field: 'per_game__points',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
    description: 'Points Per Game',
  },
  totalRebounds: {
    headerName: 'REB/G',
    field: 'per_game__total_rebounds',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
    description: 'Total Rebounds Per Game',
  },
  assists: {
    headerName: 'AST/G',
    field: 'per_game__assists',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
    description: 'Assists Per Game',
  },
  blocks: {
    headerName: 'BLK/G',
    field: 'per_game__blocks',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
    description: 'Blocks Per Game',
  },
  steals: {
    headerName: 'STL/G',
    field: 'per_game__steals',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
    description: 'Steals Per Game',
  },
  fieldGoalsMade: {
    headerName: 'FG/G',
    field: 'per_game__field_goals_made',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Field Goals Made Per Game',
  },
  fieldGoalAttempts: {
    headerName: 'FGA/G',
    field: 'per_game__field_goal_attempts',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Field Goal Attempts Per Game',
  },
  threePointFieldGoalsMade: {
    headerName: 'FG3M/G',
    field: 'per_game__three_point_field_goals_made',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: '3 Point Field Goals Made Per Game',
  },
  threePointFieldGoalAttempts: {
    headerName: 'FG3A/G',
    field: 'per_game__three_point_field_goal_attempts',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: '3 Point Field Goal Attempts Per Game',
  },
  freeThrowsMade: {
    headerName: 'FT/G',
    field: 'per_game__free_throws_made',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Free Throws Made Per Game',
  },
  freeThrowAttempts: {
    headerName: 'FTA/G',
    field: 'per_game__free_throw_attempts',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Free Thow Attempts Per Game',
  },
  offensiveRebounds: {
    headerName: 'OREB/G',
    field: 'per_game__offensive_rebounds',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Offensive Rebounds Per Game',
  },
  defensiveRebounds: {
    headerName: 'DREB/G',
    field: 'per_game__defensive_rebounds',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Defensive Rebounds Per Game',
  },
  personalFouls: {
    headerName: 'PF/G',
    field: 'per_game__personal_fouls',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Personal Fouls Per Game',
  },
  pointsInPaint: {
    headerName: 'PPTS/G',
    field: 'per_game__points_in_paint',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Points in Paint Per Game',
  },
  pointsOffTurnovers: {
    headerName: 'PTO/G',
    field: 'per_game__points_off_turnovers',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Points off Turnovers Per Game',
  },
  dunks: {
    headerName: 'DKS/G',
    field: 'per_game__dunks',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Dunks Per Game',
  },
  secondChancePoints: {
    headerName: 'PTS2/G',
    field: 'per_game__second_chance_points',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Second Change Points Per Game',
  },
  doubleDouble: {
    headerName: 'DBLDBL/G',
    field: 'per_game__double_double',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Double Double Per Game',
  },
  tripleDouble: {
    headerName: 'TDBL/G',
    field: 'per_game__triple_double',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Triple Double Per Game',
  },
  turnovers: {
    headerName: 'TO/G',
    field: 'per_game__turnovers',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Turnovers Per Game',
  },
  plusMinus: {
    headerName: '+/-/G',
    field: 'per_game__plus_minus',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Plus Minus Per Game',
  },
  franchiseScore: {
    headerName: 'TM Score/G',
    field: 'per_game__franchise_score',
    width: DEFAULT_COL_WIDTH,
    display: 'whensSelected',
    description: 'Franchise Score Per Game',
  },
  opponentScore: {
    headerName: 'OPP Score/G',
    field: 'per_game__opponent_score',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Opponent Score Per Game',
  },
};

type SumGameResultColumnKeyType = 'fieldGoalPercentage' |
  'threePointFieldGoalPercentage' |
  'freeThrowPercentage' |
  'tripleDouble' |
  'franchiseScore' |
  'opponentScore' |
  'personalFouls' |
  'assistsDifference' |
  'blocksDifference' |
  'fieldGoalsDifference' |
  'fieldGoalPercentageDifference' |
  'freeThrowsDifference' |
  'jumpBallsWonDifference' |
  'personalFoulsDifference' |
  'pointsDifference' |
  'stealsDifference' |
  'threePointFieldGoalsDifference' |
  'totalReboundsDifference' |
  'turnoversDifference' |
  'winPercentageDifference'

export const SUM_GAME_RESULT_COLUMNS: { [key in SumGameResultColumnKeyType]: ColumnMappingType } = {
  fieldGoalPercentage: {
    headerName: 'FG%',
    field: 'sum__field_goal_percentage',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
    description: 'Total Field Goal %',
  },
  threePointFieldGoalPercentage: {
    headerName: 'FG3%',
    field: 'sum__three_point_field_goal_percentage',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
    description: 'Total 3 Point Field Goal %',
  },
  freeThrowPercentage: {
    headerName: 'FT%',
    field: 'sum__free_throw_percentage',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
    description: 'Total Free Throw %',
  },
  tripleDouble: {
    headerName: 'TRBDS',
    field: 'sum__triple_double',
    width: DEFAULT_COL_WIDTH,
    display: 'alwaysThird',
    description: 'Total Triple Doubles',
  },
  franchiseScore: {
    headerName: 'TM Score',
    field: 'sum__franchise_score',
    width: DEFAULT_COL_WIDTH,
    display: 'whensSelected',
    description: 'Franchise Score',
  },
  opponentScore: {
    headerName: 'OPP Score',
    field: 'sum__opponent_score',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Opponent Score',
  },
  personalFouls: {
    headerName: 'PF',
    field: 'sum__personal_fouls',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Personal Fouls',
  },
  assistsDifference: {
    headerName: 'AST Diff',
    field: 'sum__assists_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Assists Difference',
  },
  blocksDifference: {
    headerName: 'BLK Diff',
    field: 'sum__blocks_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Blocks Difference',
  },
  fieldGoalsDifference: {
    headerName: 'FGDIFF',
    field: 'sum__field_goals_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Field Goals Difference',
  },
  fieldGoalPercentageDifference: {
    headerName: 'FG%DIFF',
    field: 'sum__field_goal_percentage_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Field Goal % Difference',
  },
  freeThrowsDifference: {
    headerName: 'FTDIFF',
    field: 'sum__free_throws_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Free Throws Difference',
  },
  jumpBallsWonDifference: {
    headerName: 'JBWDIFF',
    field: 'sum__jump_balls_won_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Jump Balls Won Difference',
  },
  personalFoulsDifference: {
    headerName: 'PFDIFF',
    field: 'sum__personal_fouls_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Personal Foulds Difference',
  },
  pointsDifference: {
    headerName: 'SC Diff',
    field: 'sum__points_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Score Difference',
  },
  stealsDifference: {
    headerName: 'STLDIFF',
    field: 'sum__steals_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Steals Difference',
  },
  threePointFieldGoalsDifference: {
    headerName: 'FG3DIFF',
    field: 'sum__three_point_field_goals_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: '3 Points Field Goals Difference',
  },
  totalReboundsDifference: {
    headerName: 'REBDIFF',
    field: 'sum__total_rebounds_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Total Rebounds Difference',
  },
  turnoversDifference: {
    headerName: 'TODIFF',
    field: 'sum__turnovers_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Turnovers Difference',
  },
  winPercentageDifference: {
    headerName: 'PDIFF',
    field: 'sum__win_percentage_difference',
    width: DEFAULT_COL_WIDTH,
    display: 'whenSelected',
    description: 'Win Percentage Difference',
  },
};
