import React from 'react';
import { generatePath } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Paper } from '@mui/material';
import BoxScore from './BoxScore';
import GamePlayerStatsTable from './GamePlayerStatsTable';
// import RecentGames from './RecentGames';
import { GameBoxScorePlayerStatsType, GameBoxScoreType } from '../../types/game';
import ROUTER_URLS from '../../utils/router/urls';

interface ScoringType {
  total: number;
  first_half: number;
  second_half: number;
  overtime_total?: number | undefined;
}

interface IProps {
  gameData: GameBoxScoreType;
}

const mapPlayerGameData = (data: GameBoxScorePlayerStatsType[]) =>
  data.map((row) => {
    const playerLink = (
      <a href={generatePath(ROUTER_URLS.ncaambb.player, { playerId: row.player_id })}>{row.name}</a>
    );
    return {
      playerId: row.player_id,
      playerName: row.name,
      playerLink,
      minutes: row.stats.minutes,
      fieldGoals: row.stats.field_goals_made,
      fieldGoalAttempts: row.stats.field_goal_attempts,
      threePointFgs: row.stats.three_point_field_goals_made,
      threePointFgas: row.stats.three_point_field_goal_attempts,
      freeThrows: row.stats.free_throws_made,
      freeThrowAttempts: row.stats.free_throw_attempts,
      offensiveRebounds: row.stats.offensive_rebounds,
      defensiveRebounds: row.stats.defensive_rebounds,
      rebounds: row.stats.total_rebounds,
      assists: row.stats.assists,
      steals: row.stats.steals,
      blocks: row.stats.blocks,
      turnovers: row.stats.turnovers,
      personalFouls: row.stats.personal_fouls,
      points: row.stats.points,
    }
  });

const formattedTeamRecord = (teamWins: number = 0, teamLosses: number = 0, conferenceWins: number = 0, conferenceLosses: number = 0, conferenceAbbr: string = '') => (
  `${teamWins}-${teamLosses}, ${conferenceWins}-${conferenceLosses} ${conferenceAbbr}`
); 

const mapBoxScoreData = (data: GameBoxScoreType) => {
  const venueState = data.overview.venue.state ? `, ${data.overview.venue.state}` : '';
  const venue = data.overview.venue && data.overview.venue.name && data.overview.venue.name.length > 0 ?
    `${data.overview.venue.name} ${data.overview.venue.city}${venueState}` : '';
  const date = `${data.date}`;

  let numberOfOvertimes = 0;
  if (data.overview.home?.scoring) {
    const keys = Object.keys(data.overview.home?.scoring);
    // find all keys in the scoring object that have overtime in their name and do not have null values
    // remove 1 because we also sent the overtime total
    numberOfOvertimes = keys.filter((key) => {
      const scoring = data.overview.home?.scoring;
      const value: any = scoring ? scoring[key as keyof ScoringType] : 0;
      return key.includes('overtime') && Boolean(value)
    }).length - 1;
  }
  
  const home = {
    teamName: data.overview.home?.short_name || '',
    teamRecord: formattedTeamRecord(
      data.overview.home?.franchise_wins,
      data.overview.home?.franchise_losses,
      data.overview.home?.franchise_conference_wins,
      data.overview.home?.franchise_conference_losses,
      data.overview.home?.franchise_conference_abbr,
    ),
    teamScore: Number(data.overview.home?.score),
    teamWin: Number(data.overview.home?.score) > Number(data.overview.visitor?.score),
    coachName: data.overview.home?.head_coach || '',
    gameStats: mapPlayerGameData(data.overview.home?.game_stats || []),
    firstHalfScore: Number(data.overview.home?.scoring?.first_half),
    secondHalfScore: Number(data.overview.home?.scoring?.second_half),
    overtimeScore: Number(data.overview.home?.scoring?.overtime_total),
    rank: data.overview.home?.franchise_rank,
  };

  const visitor = {
    teamName: data.overview.visitor?.short_name || '',
    teamRecord: formattedTeamRecord(
      data.overview.visitor?.franchise_wins,
      data.overview.visitor?.franchise_losses,
      data.overview.visitor?.franchise_conference_wins,
      data.overview.visitor?.franchise_conference_losses,
      data.overview.visitor?.franchise_conference_abbr,
    ),
    teamScore: Number(data.overview.visitor?.score),
    teamWin: Number(data.overview.visitor?.score) > Number(data.overview.home?.score),
    coachName: data.overview.visitor?.head_coach || '',
    gameStats: mapPlayerGameData(data.overview.visitor?.game_stats || []),
    firstHalfScore: Number(data.overview.visitor?.scoring?.first_half),
    secondHalfScore: Number(data.overview.visitor?.scoring?.second_half),
    overtimeScore: Number(data.overview.visitor?.scoring?.overtime_total),
    rank: data.overview.visitor?.franchise_rank,
  };

  return {
    venue,
    date,
    numberOfOvertimes,
    home,
    visitor,
  };
};

function GameLayout({ gameData }: IProps) {
  const boxScoreData = mapBoxScoreData(gameData);

  return (
    <Grid container direction='row' spacing={3} marginY={5} marginX={4}>
      <Grid item xs={9}>
        <Grid container direction="column">
          <Grid item>
            <Paper elevation={2}>
              <BoxScore data={boxScoreData} />
            </Paper>
          </Grid>
          <Grid item>
            <GamePlayerStatsTable
              teamName={boxScoreData.visitor.teamName}
              coachName={boxScoreData.visitor.coachName}
              data={boxScoreData.visitor.gameStats}
            />
          </Grid>
          <Grid item>
            <GamePlayerStatsTable
              teamName={boxScoreData.home.teamName}
              coachName={boxScoreData.home.coachName}
              data={boxScoreData.home.gameStats}
            />
          </Grid>
        </Grid>  
      </Grid>
      <Grid item xs={3}>
      {
      //  <Grid container direction="column">
      //    <Grid item>
      //      <Paper elevation={2}>
      //        <RecentGames />
      //      </Paper>
      //    </Grid>  
      //  </Grid>
      }
      </Grid>
    </Grid>
  );
}

export default GameLayout;
