import {
  DEFAULT_ACTIVE_STATUS,
  DEFAULT_HOME_AWAY,
  DEFAULT_OVERTIME_STATUS,
  DEFAULT_ROOKIE_STATUS,
  DEFAULT_WIN_LOSS_STATUS,
  activeStatusOptions,
  homeAwayOptions,
  overtimeStatusOptions,
  rookieStatusOptions,
  winLossStatusOptions
} from "../../compounds/FastFilters/constants";
import { GameClass, GameClassType } from '../../../api/types';
import { DEFAULT_STREAK_RANGE_OPTION } from "../../pages/NcaaMbb/PlayerStreakFinder/constants";
import { DEFAULT_SPAN_RANGE_OPTION } from "../../pages/NcaaMbb/PlayerSpanFinder/constants";
import { StatFilter } from "../../compounds/EliasStatsFilter/filter-stats-utils";
import { ContextAdvancedFiltersType, ContextFilterType, ContextGameTypeFiltersType } from "../../types/context";
import { SelectedStatFilterType } from "../../types/statsFilter";
import {
  ALL_CONFERENCE_TOURNAMENT_IDS,
  CONF_TOURNAMENTS,
  CONF_TOURNAMENT_ROUNDS,
  CURRENT_SEASON_YEAR,
  NCAA_ROUNDS,
  POST_SEASON_TOURNAMENTS,
} from "./constants";

const mapEqualitySymbolToSearchParam = (symbol?: string): string => {
  switch (symbol) {
    case '<=':
      return '__lte=';
    case '>=':
      return '__gte=';
    default:
      return '=';
  }
};

const mapValueToSearchValue = (
  id: string,
  value?: string,
  searchModifierFunction?: (
    value: string,
    modifierValue: number,
  ) => string,
  searchModifierValue?: string,
): string | number => {
  if (!value) return '';
  if (searchModifierFunction) {
    const modValue = Number(searchModifierValue);
    return searchModifierFunction(value, modValue);
  }
  if (id === 'seconds') {
    return Number(value) * 60;
  }
  if (id === 'last_game_result') {
    return value === '1' ? 'W' : 'L';
  };
  return value;
};

const mapIdToSearchId = (id: string) => {
  if (id.includes('_per_game')) {
    return id.replace('_per_game', '');
  }
  return id;
};

export const formatStatsFilterForSearch = (
  filters: SelectedStatFilterType[],
  statsFilterOptions: StatFilter[],
  searchModifierValue?: string,
): string => {
  if (filters.length === 0) return '';

  let years: number[] = [];
  let searchFilters = filters.filter((filter) => filter.value && filter.value.length > 0).map((filter) => {
    const statFilterOption = statsFilterOptions.find(
      (statFilter) => statFilter.id === filter.id
    );
    if (statFilterOption) {
      let symbol = mapEqualitySymbolToSearchParam(filter.equalitySymbol);
      const formattedValue = mapValueToSearchValue(filter.id, filter.value, statFilterOption.searchModifier, searchModifierValue);
      const formattedId = mapIdToSearchId(filter.id);
      if (statFilterOption.year) {
        years = years.concat(statFilterOption.year);
      }
      if (symbol === '__gte=' && (statFilterOption.id === 'franchise_rank' || statFilterOption.id === 'opponent_rank')) {
        symbol = '__gte_or_null='
      }
      return `${formattedId}${symbol}${formattedValue}`;
    }

    return '';
  });
  const filterYear = Math.max(...years);
  if (filterYear > 0) {
    searchFilters = searchFilters.concat(`season__gte=${filterYear}`);
  }
  return searchFilters.join('&');
};

const formatGameTypeFilters = (gameFilterContext: ContextGameTypeFiltersType): string => {
  const filters: string[] = [];

  // handle radio buttons and their inputs
  if (gameFilterContext.holiday_group.length > 0) {
    switch (gameFilterContext.holiday_group) {
      case 'game__date': {
        const id = gameFilterContext.holiday_group;
        const start = gameFilterContext.holiday_group_value;
        const end = gameFilterContext.holiday_group_end_value;
        const formattedStart = start.replace(/[/|-]/g, '%2F');
        const formattedEnd = end.replace(/[/|-]/g, '%2F');
        filters.push(`${id}__gte=${formattedStart}&${id}__lte=${formattedEnd}`);
        break;
      }
      default:
        filters.push(`${gameFilterContext.holiday_group}=1`);
        break;
    }
  }

  if (gameFilterContext.postseason_group.length > 0) {
    switch (gameFilterContext.postseason_group) {
      case 'game_franchise__game_number':
        filters.push(`game_franchise__game_number=${gameFilterContext.postseason_group_value}&game__game_class=PS`);
        break;
      case 'opening_game':
        filters.push(`game_franchise__game_number=1&game__game_class=PS`);
        break;
      default:
        filters.push(`${gameFilterContext.postseason_group}=1&game__game_class=PS`);
        break;
    }
  }

  if (gameFilterContext.season_group.length > 0) {
    switch (gameFilterContext.season_group) {
      case 'season_range': {
        const formattedStart = gameFilterContext.season_group_value.split('-')[0];
        const formattedEnd = gameFilterContext.season_group_end_value.split('-')[0];
        filters.push(`season__gte=${formattedStart}&season__lte=${formattedEnd}`);
        break;
      }
      case '3_point_era':
        filters.push(`season__gte=1979&season__lte=${CURRENT_SEASON_YEAR}`);
        break;
      case 'since_merger':
        filters.push(`season__gte=1976&season__lte=${CURRENT_SEASON_YEAR}`);
        break;
      case 'shot_clock_era':
        filters.push(`season__gte=1954&season__lte=${CURRENT_SEASON_YEAR}`);
        break;
      default:
        filters.push(`season=${CURRENT_SEASON_YEAR}`);
        break;
    }
  }

  // handle checkboxes
  const tournamentRoundOptions: string[] = [];
  const validCheckboxFilters = Object.entries(gameFilterContext).filter((pair) => pair[1] === true);
  const validCheckboxFilterKeys = validCheckboxFilters.map(pair => pair[0]);
  validCheckboxFilters.forEach((checkboxFilter: [string, any]) => {
    const key = checkboxFilter[0];
    const keyUpper = key.toUpperCase();

    if (GameClassType.includes(keyUpper)) {
      filters.push(`game__game_class=${keyUpper}`);
    }
    else if (GameClass.includes(keyUpper)) {
      filters.push(`game__game_class=${keyUpper}`);
    }

    // Conference game y/n
    else if (keyUpper === 'CONFERENCE_GAME') {
      filters.push(`game__conference_game=${true}`);
    }

    // Post-season tournaments
    else if (Object.keys(POST_SEASON_TOURNAMENTS).includes(key)) {
      const value: number = POST_SEASON_TOURNAMENTS[key];
      filters.push(`game__tournament__id=${value}`);
    }
    else if (gameFilterContext.ncaa_tournament && Object.keys(NCAA_ROUNDS).includes(key)) {
      const roundValue: string = NCAA_ROUNDS[key];
      // stash the value for later processing
      tournamentRoundOptions.push(roundValue);
    }

    // Conference tournaments
    else if (key === 'any_conference_tournament') {
      const value = ALL_CONFERENCE_TOURNAMENT_IDS;
      filters.push(`game__tournament__id__in=${value}`);
    }
    else if (Object.keys(CONF_TOURNAMENTS).includes(key)) {
      const value: number = CONF_TOURNAMENTS[key];
      filters.push(`game__tournament__id=${value}`);
    }
    else if (Object.keys(CONF_TOURNAMENT_ROUNDS).includes(key)) {
      const confTournamentSelected = validCheckboxFilterKeys.filter(validKey => (
        validKey === 'any_conference_tournament' || Object.keys(CONF_TOURNAMENTS).includes(validKey)
      ));
      if (confTournamentSelected.length > 0) {
        const roundValue: string = CONF_TOURNAMENT_ROUNDS[key];
        // stash the value for later processing
        tournamentRoundOptions.push(roundValue);
      }
    }
  });

  // Handle collected tournament round options selected by the user
  if (tournamentRoundOptions.length === 1) {
    filters.push(`game__tournament_round=${tournamentRoundOptions[0]}`);
  } else if (tournamentRoundOptions.length > 1) {
    const combinedValue = tournamentRoundOptions.join(',');
    filters.push(`game__tournament_round__in=${combinedValue}`);
  }

  return filters.join('&');
};

const formatAdvancedFilters = (advancedFilterContext: ContextAdvancedFiltersType): string => {
  const filters: string[] = [];
  if (advancedFilterContext.player_age.selected) {
    const searchEquality = mapEqualitySymbolToSearchParam(advancedFilterContext.player_age.equalityOption);
    filters.push(`age${searchEquality}${advancedFilterContext.player_age.value}`);
  }
  if (advancedFilterContext.seasons.selected) {
    const searchEquality = mapEqualitySymbolToSearchParam(advancedFilterContext.seasons.equalityOption);
    filters.push(`seasons${searchEquality}${advancedFilterContext.seasons.value}`);
  }

  const validChecboxFilters = Object.entries(advancedFilterContext).filter((pair) => pair[1] === true);
  validChecboxFilters.forEach((checkboxFilter: [string, any]) => {
    filters.push(`${checkboxFilter[0]}=1`);
  });
  return filters.join('&');
};

export const appendContextFilters = (
  localFilters: string[],
  context: ContextFilterType,
  statsFilterOptions: StatFilter[],
  searchModifierValue?: string,
) => {
  const filters = localFilters;

  if (context.selectedStatsFilters && context.selectedStatsFilters.length > 0) {
    filters.push(formatStatsFilterForSearch(context.selectedStatsFilters, statsFilterOptions, searchModifierValue));
  }

  filters.push(formatGameTypeFilters(context.gameTypeFilters));

  filters.push(formatAdvancedFilters(context.advancedFilters));

  // Boolean filters
  if (activeStatusOptions.map(option => option.id).includes(context.activeStatus) &&
      context.activeStatus !== DEFAULT_ACTIVE_STATUS) {
    filters.push(`player__active=${context.activeStatus === 'active'}`);
  }
  if (homeAwayOptions.map(option => option.id).includes(context.homeAway) &&
      context.homeAway !== DEFAULT_HOME_AWAY) {
    let ncaaHomeAwayFilter = '';
    if (context.homeAway === 'home') ncaaHomeAwayFilter = 'H';
    if (context.homeAway === 'away') ncaaHomeAwayFilter = 'R';
    if (context.homeAway === 'neutral') ncaaHomeAwayFilter = 'N';
    filters.push(`home_game=${ncaaHomeAwayFilter}`);
  }
  if (rookieStatusOptions.map(option => option.id).includes(context.rookieStatus) &&
      context.rookieStatus !== DEFAULT_ROOKIE_STATUS) {
    filters.push(`rookie=${context.rookieStatus === 'rookie'}`);
  }
  if (winLossStatusOptions.map(option => option.id).includes(context.winLossStatus) &&
      context.winLossStatus !== DEFAULT_WIN_LOSS_STATUS) {
    filters.push(`win=${context.winLossStatus === 'win'}`);
  }
  if (overtimeStatusOptions.map(option => option.id).includes(context.overtimeStatus) &&
      context.overtimeStatus !== DEFAULT_OVERTIME_STATUS) {
    filters.push(`game__overtime_played=${context.overtimeStatus === 'overtime'}`);
  }

  // Enumerated filters
  if (context.playerObject) {
    filters.push(`player__id=${context.playerObject.id}`);
  }
  if (context.opposingPlayerObject) {
    filters.push(`played_against=${context.opposingPlayerObject.id}`);
  }
  if (context.playerTeammates && context.playerTeammates.length > 0) {
    const ids = context.playerTeammates.map((option) => option.id).join(',');
    filters.push(`played_with=${ids}`)
  }
  if (context.teamObject) {
    filters.push(`franchise_season__franchise_id=${context.teamObject.franchise_id}`);
  }
  if (context.venueObject) {
    filters.push(`game__venue=${context.venueObject.venue}`);
  }
  if (context.opponentObject) {
    filters.push(`opponent_season__franchise_id=${context.opponentObject.franchise_id}`);
  }
  if (context.conferenceObject) {
    filters.push(`franchise_conference_id=${context.conferenceObject.conference_id}`);
  }
  if (context.opponentConferenceObject) {
    filters.push(`opponent_conference_id=${context.opponentConferenceObject.conference_id}`);
  }
  if (context.streakGameNumber) {
    filters.push(`streak_length=${context.streakGameNumber}`);
  }
  if (context.streakRangeOption && context.streakRangeOption !== DEFAULT_STREAK_RANGE_OPTION) {
    filters.push(`streak_range=${context.streakRangeOption}`);
  }
  if (context.spanLength) {
    filters.push(`span_length=${context.spanLength}`);
  }
  if (context.spanRangeOption && context.spanRangeOption !== DEFAULT_SPAN_RANGE_OPTION) {
    filters.push(`span_range=${context.spanRangeOption}`);
  }
  if (context.groupBy && context.groupBy !== 'none') {
    filters.push(`group_by=${context.groupBy}`);
  };

  // remove any empty strings or null values so they dont get sent to api
  const apiFilters = filters.filter(Boolean);
  return apiFilters;
};

export const searchEnabled = (
  context: ContextFilterType,
  pageSpecificSearchEnabled: (context: ContextFilterType) => boolean,
  // eslint-disable-next-line arrow-body-style
): boolean => {
  return context.searchEnabled && pageSpecificSearchEnabled(context) && (
    // stats filters selected
    context.selectedStatsFilters.length > 0 ||

    // fast filters selected
    (context.activeStatus !== DEFAULT_ACTIVE_STATUS && context.activeStatus.length > 0) ||
    (context.rookieStatus !== DEFAULT_ROOKIE_STATUS && context.rookieStatus.length > 0) ||
    (context.homeAway !== DEFAULT_HOME_AWAY && context.homeAway.length > 0) ||
    (context.winLossStatus !== DEFAULT_WIN_LOSS_STATUS && context.winLossStatus.length > 0) ||
    (context.overtimeStatus !== DEFAULT_OVERTIME_STATUS && context.overtimeStatus.length > 0) ||

    // autocomplete filter selected
    Boolean(context.playerObject && context.playerObject.id) ||
    Boolean(context.opposingPlayerObject && context.opposingPlayerObject.id) ||
    Boolean(context.playerTeammates && context.playerTeammates.length > 0) ||
    Boolean(context.venueObject && context.venueObject.venue) ||
    Boolean(context.teamObject && context.teamObject.id) ||
    Boolean(context.opponentObject && context.opponentObject.id) ||

    // modal filters
    context.gameTypeFilters.holiday_group.length > 0 ||
    context.gameTypeFilters.postseason_group.length > 0 ||
    context.gameTypeFilters.season_group.length > 0 ||
    context.gameTypeFilters.pin || context.gameTypeFilters.sem || context.gameTypeFilters.rd1 ||
    context.gameTypeFilters.as || context.gameTypeFilters.fin || context.gameTypeFilters.con
  );
};
