import React from 'react';
import { Button } from "@mui/material";
import { useFilterDispatchContext } from '../../contexts/FilterContext';

function ResetButton() {
  const filterDispatchContext: any = useFilterDispatchContext();

  const resetFilters = () => {
    filterDispatchContext({
      type: 'resetFilters',
    });
  };

  return (
    <Button variant="outlined" onClick={resetFilters}>
      Reset
    </Button>
  );
}

export default ResetButton;