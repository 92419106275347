import React from 'react';
import { useParams } from 'react-router-dom';
import Dashboard from '../../../compounds/Dashboard/Dashboard';
import GameLayout from '../../../compounds/GameLayout/GameLayout';
import API_URLS from '../../../../api/api_urls';
import { useFetchGameBoxScore } from '../../../hooks/useFetch';

function NcaaMbbGame() {
  const { gameId } = useParams();
  const apiUrl = API_URLS.ncaambb;

  const { data: gameData, isLoading: loading, error } = useFetchGameBoxScore(gameId || '', apiUrl.boxscore);

  if (loading) {
    return <span>Loading...</span>
  }

  if (error && error instanceof Error) {
    return <span>Error: {error.message}</span>
  }

  if (!gameData) {
    return <span>Error: failed to load game data</span>
  }

  return (
    <Dashboard>
      <GameLayout gameData={gameData} />
    </Dashboard>
  );
}

export default NcaaMbbGame;
