import React, { createContext, useContext, useReducer } from "react";
import { Outlet } from "react-router-dom";

import {
  DEFAULT_ACTIVE_STATUS,
  DEFAULT_HOME_AWAY,
  DEFAULT_ROOKIE_STATUS,
  DEFAULT_WIN_LOSS_STATUS,
  DEFAULT_OVERTIME_STATUS,
  DEFAULT_QUARTER_HALF_STATUS,
} from "../compounds/FastFilters/constants";
import { DEFAULT_SPAN_RANGE_OPTION } from "../pages/Nba/PlayerSpanFinder/constants";
import { DEFAULT_STREAK_RANGE_OPTION } from '../pages/Nba/PlayerStreakFinder/constants';
import { ContextAdvancedFiltersType, ContextFilterType, ContextGameTypeFiltersType } from "../types/context";
import { ModalChipType } from "../types/modal";

const defaultGameTypeChips: ModalChipType[] = [
  {
    id: 'reg',
    label: 'Regular Season',
  }
];

const defaultAdvancedFilterChips: ModalChipType[] = [];

const initialAdvancedFilters: ContextAdvancedFiltersType = {
  player_age: {
    selected: false,
    value: '',
    equalityOption: '>=',
  },
  career_game_number: {
    selected: false,
    value: '',
    equalityOption: '>=',
  },
  season_game_number: {
    selected: false,
    value: '',
    equalityOption: '>=',
  },
  debut_game: false,
  ps_debut_game: false,
  seasons: {
    selected: false,
    value: '',
    equalityOption: '>=',
  },
};

const initialGameTypeFilters: ContextGameTypeFiltersType = {
  reg: true,
  ps: false,
  pin: false,
  as: false,
  fin: false,
  con: false,
  sem: false,
  rd1: false,

  holiday_group: '',
  holiday_group_value: '',
  holiday_group_end_value: '',
  postseason_group: '',
  postseason_group_value: '',
  postseason_group_end_value: '',
  season_group: '',
  season_group_value: '',
  season_group_end_value: '',

  // NCAA-specifc filters, should be ignored otherwise
  ncaa_tournament: false,
  third_place_game: false,
  play_in_round: false,
  round_of_64: false,
  round_of_32: false,
  sweet_sixteen: false,
  elite_eight: false,
  semi_finals: false,
  championship: false,
  conference_game: false,
};

const ncaaMbbSortOverrides = {
  sortBy: '-game__date',
  sortByField: 'game__date',
  sortByDirection: '-',
};

const initialFilters: ContextFilterType = {
  searchEnabled: false,
  groupBy: 'none',
  streakRangeOption: DEFAULT_STREAK_RANGE_OPTION,
  spanRangeOption: DEFAULT_SPAN_RANGE_OPTION,
  sortBy: '-game__date',
  sortByField: 'game__date',
  sortByDirection: '-',
  page: 0,
  activeStatus: DEFAULT_ACTIVE_STATUS,
  rookieStatus: DEFAULT_ROOKIE_STATUS,
  homeAway: DEFAULT_HOME_AWAY,
  winLossStatus: DEFAULT_WIN_LOSS_STATUS,
  overtimeStatus: DEFAULT_OVERTIME_STATUS,
  halfQuarterStatus: DEFAULT_QUARTER_HALF_STATUS,
  playerObject: null,
  opposingPlayerObject: null,
  playerTeammates: [],
  venueObject: null,
  teamObject: null,
  conferenceObject: null,
  opponentConferenceObject: null,
  opponentObject: null,
  streakGameNumber: '',
  spanLength: '',
  selectedStatsFilters: [],
  statsFilterChips: [],
  gameTypeFilters: {...initialGameTypeFilters},
  gameTypeFiltersChips: defaultGameTypeChips,
  advancedFilters: {...initialAdvancedFilters},
  advancedFiltersChips: defaultAdvancedFilterChips,
};

export const FilterContext: any = createContext<ContextFilterType | null>(null);
export const FilterDispatchContext: any = createContext(null);

export const useFilterContext = () => useContext(FilterContext);
export const useFilterDispatchContext = () => useContext(FilterDispatchContext);

const filterReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'sortByChanged':
      return {
        ...state,
        sortBy: action.sortBy,
        page: 0,
      };
    case 'pageByChanged':
      return {
        ...state,
        page: action.page,
      };
    case 'selectedStatFiltersChanged': {
      const filters = action.selectedStatsFilters;
      const filterChips = action.chips;
      return {
        ...state,
        searchEnabled: true,
        selectedStatsFilters: filters,
        statsFilterChips: filterChips,
      };
    }
    case 'groupBySelected':
      return {
        ...state,
        groupBy: action.groupBy,
        searchEnabled: action.searchEnabled,
      };
    case 'streakOptionsSelected':
      return {
        ...state,
        streakRangeOption: action.streakRangeOption,
        searchEnabled: true,
      };
    case 'spanOptionSelected':
      return {
        ...state,
        spanRangeOption: action.spanRangeOption,
        searchEnabled: true,
      };
    case 'activeStatusChange':
      return {
        ...state,
        activeStatus: action.activeStatus,
        searchEnabled: action.searchEnabled,
      };
    case 'rookieStatusChange':
      return {
        ...state,
        rookieStatus: action.rookieStatus,
        searchEnabled: action.searchEnabled,
      };
    case 'homeAwayChange':
      return {
        ...state,
        homeAway: action.homeAway,
        searchEnabled: action.searchEnabled,
      };
    case 'winLossStatusChange':
      return {
        ...state,
        winLossStatus: action.winLossStatus,
        searchEnabled: action.searchEnabled,
      };
    case 'overtimeStatusChange':
      return {
        ...state,
        overtimeStatus: action.overtimeStatus,
        searchEnabled: action.searchEnabled,
      };
    case 'halfQuarterStatusChange':
      return {
        ...state,
        halfQuarterStatus: action.halfQuarterStatus,
        searchEnabled: action.searchEnabled,
      };    
    case 'changeVenueObject':
      return {
        ...state,
        venueObject: action.venueObject,
        searchEnabled: action.searchEnabled,
      };
    case 'changeTeamObject':
      return {
        ...state,
        teamObject: action.teamObject,
        searchEnabled: action.searchEnabled,
      };
    case 'changeConferenceObject':
      return {
        ...state,
        conferenceObject: action.conferenceObject,
        searchEnabled: action.searchEnabled,
      };
    case 'changeOpponentConferenceObject':
      return {
        ...state,
        opponentConferenceObject: action.opponentConferenceObject,
        searchEnabled: action.searchEnabled,
      };  
    case 'changeOpponentObject':
      return {
        ...state,
        opponentObject: action.opponentObject,
        searchEnabled: action.searchEnabled,
      };
    case 'changePlayerObject':
      return {
        ...state,
        playerObject: action.playerObject,
        searchEnabled: action.searchEnabled,
      };
    case 'changeOpposingPlayerObject':
      return {
        ...state,
        opposingPlayerObject: action.opposingPlayerObject,
        searchEnabled: action.searchEnabled,
      };
    case 'changePlayerTeammates':
      return {
        ...state,
        playerTeammates: action.playerTeammates,
        searchEnabled: action.searchEnabled,
      };
    case 'changeStreakGameNumber':
      return {
        ...state,
        streakGameNumber: action.streakGameNumber,
        searchEnabled: true,
      };
    case 'changeSpanLength':
      return {
        ...state,
        spanLength: action.spanLength,
        searchEnabled: true,
      };
    case 'changeSearchStatus':
      return {
        ...state,
        searchEnabled: action.searchEnabled,
      };
    case 'changeModalFilter': {
      const filterGroupKey = action.filter.filterGroup;
      // eslint-disable-next-line prefer-destructuring
      const groupTypeFilters = { ...state[filterGroupKey] };
      groupTypeFilters[action.filter.key] = action.filter.value;

      return {
        ...state,
        searchEnabled: true,
        [filterGroupKey]: {
          ...groupTypeFilters,
        }
      }
    }
    case 'changeModalCheckboxWithInput': {
      const filterGroupKey: keyof ContextFilterType = action.filter.filterGroup;
      // eslint-disable-next-line prefer-destructuring
      const groupTypeFilters = { ...state[filterGroupKey] };
      const checkboxInput = { ...groupTypeFilters[action.filter.key] };

      checkboxInput.selected = action.filter.selected;
      checkboxInput.value = action.filter.value;
      checkboxInput.equalityOption = action.filter.equalityOption;
      groupTypeFilters[action.filter.key] = checkboxInput;

      return {
        ...state,
        searchEnabled: true,
        [filterGroupKey]: {
          ...groupTypeFilters,
        }
      }
    }
    case 'clearModalRadioFilter': {
      const filterGroupKey = action.filter.filterGroup;
      const radioGroupKey = action.filter.key;
      const radioGroupInputKey = `${radioGroupKey}_value`;
      const radioGroupEndInputKey = `${radioGroupKey}_end_value`;

      // eslint-disable-next-line prefer-destructuring
      const groupTypeFilters = { ...state[filterGroupKey] };
      groupTypeFilters[radioGroupKey] = initialGameTypeFilters[radioGroupKey as keyof ContextGameTypeFiltersType];
      groupTypeFilters[radioGroupInputKey] = initialGameTypeFilters[radioGroupInputKey as keyof ContextGameTypeFiltersType];
      groupTypeFilters[radioGroupEndInputKey] = initialGameTypeFilters[radioGroupEndInputKey as keyof ContextGameTypeFiltersType];

      return {
        ...state,
        [filterGroupKey]: {
          ...groupTypeFilters
        }
      }
    }
    case 'clearAdvancedFilter': {
      const filterGroupKey = action.filter.filterGroup;
      const inputFilterKey = action.filter.key;

      // eslint-disable-next-line prefer-destructuring
      const groupTypeFilters = { ...state[filterGroupKey] };
      const initialFilter = initialAdvancedFilters[inputFilterKey as keyof ContextAdvancedFiltersType]
      groupTypeFilters[inputFilterKey] = initialFilter;

      return {
        ...state,
        [filterGroupKey]: {
          ...groupTypeFilters
        }
      }
    }
    case 'advancedFiltersChipsUpdate': {
      const filterChips = action.chips;
      return {
        ...state,
        advancedFiltersChips: filterChips,
      };
    }
    case 'gameTypeFiltersChipsUpdate': {
      const filterChips = action.chips;
      return {
        ...state,
        gameTypeFiltersChips: filterChips,
      };
    }
    // TODO: JN, readyToSearch does not appear to be used anywhere yet
    case 'readyToSearch':
      return {
        ...state,
        page: 0,
        sortBy: '-game__date',
        searchEnabled: false,
      };
    case 'gameTypeFiltersReset':
      return {
        ...state,
        gameTypeFilters: {
          ...initialGameTypeFilters,
        },
        gameTypeFiltersChips: defaultGameTypeChips,
      };
    case 'advancedFiltersReset':
      return {
        ...state,
        advancedFilters: {
          ...initialAdvancedFilters,
        },
        advancedFiltersChips: defaultAdvancedFilterChips,
      };
    case 'resetFilters':
      return {
        ...state,
        ...initialFilters,
      };
    case 'loadFilters': {
      return {
        ...state,
        ...action.apiFilters,
        gameTypeFilters: { ...action.apiFilters.gameTypeFilters },
        gameTypeFiltersChips: action.apiFilters.gameTypeFiltersChips,
        advancedFilters: { ...action.apiFilters.advancedFilters },
        advancedFiltersChips: action.apiFilters.advancedFiltersChips,
      };
    }
    default:
      return state;
  }
};

export function NbaPlayerGameFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    { ...initialFilters },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function NbaPlayerStreakFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    { ...initialFilters },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};


export function NbaPlayerSpanFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    { ...initialFilters },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function NbaTeamGameFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    { ...initialFilters },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function NbaTeamStreakFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    { ...initialFilters },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function NbaTeamSpanFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    { ...initialFilters },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function NcaaMbbPlayerGameFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    {
      ...initialFilters,
      ...ncaaMbbSortOverrides,
      gameTypeFilters: {
        ...initialGameTypeFilters,
        ps: false,
        reg: false,
      },
      gameTypeFiltersChips: [],
    },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function NcaaMbbPlayerStreakFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    {
      ...initialFilters,
      ...ncaaMbbSortOverrides,
      gameTypeFilters: {
        ...initialGameTypeFilters,
        ps: false,
        reg: false,
      },
      gameTypeFiltersChips: [],
    },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};


export function NcaaMbbPlayerSpanFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    {
      ...initialFilters,
      ...ncaaMbbSortOverrides,
      gameTypeFilters: {
        ...initialGameTypeFilters,
        ps: false,
        reg: false,
      },
      gameTypeFiltersChips: [],
    },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function NcaaMbbTeamStreakFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    {
      ...initialFilters,
      ...ncaaMbbSortOverrides,
      gameTypeFilters: {
        ...initialGameTypeFilters,
        ps: false,
        reg: false,
      },
      gameTypeFiltersChips: [],
    },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function NcaaMbbTeamSpanFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    {
      ...initialFilters,
      ...ncaaMbbSortOverrides,
      gameTypeFilters: {
        ...initialGameTypeFilters,
        ps: false,
        reg: false,
      },
      gameTypeFiltersChips: [],
    },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function NcaaMbbTeamGameFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    {
      ...initialFilters,
      ...ncaaMbbSortOverrides,
      gameTypeFilters: {
        ...initialGameTypeFilters,
        ps: false,
        reg: false,
      },
      gameTypeFiltersChips: [],
    },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function WnbaPlayerGameFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    { ...initialFilters },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function WnbaPlayerStreakFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    { ...initialFilters },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};


export function WnbaPlayerSpanFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    { ...initialFilters },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function WnbaTeamGameFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    { ...initialFilters },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function WnbaTeamStreakFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    { ...initialFilters },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};

export function WnbaTeamSpanFinderContextProvider() {
  const [contextState, dispatch] = useReducer(
    filterReducer,
    { ...initialFilters },
  );

  return (
    <FilterContext.Provider value={contextState}>
      <FilterDispatchContext.Provider value={dispatch}>
        <Outlet />
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};
