type TournamentOptionsType = {
  [key: string]: string,
};

type TournamentIdType = {
  [key: string]: number,
};

export const CURRENT_SEASON_YEAR = 2023;
export const NCAA_ROUNDS: TournamentOptionsType = {
  'third_place_game': 'SM2',
  'play_in_round': 'PIN',
  'round_of_64': 'R64',
  'round_of_32': 'R32',
  'sweet_sixteen': 'R16',
  'elite_eight': 'QTR',
  'semi_finals': 'SEM',
  'championship': 'FIN'
};

export const NCAA_TOURNAMENT_ID = 1;
export const POST_SEASON_TOURNAMENTS: TournamentIdType = {
  'ncaa_tournament': NCAA_TOURNAMENT_ID,
  'basketball_classic': 45,
  'cbi_tournament': 3,
  'cit_tournament': 4,
  'nit_tournament': 2
};

export const CONF_TOURNAMENT_ROUNDS: TournamentOptionsType = {
  'all_rounds': '',
  'quarterfinals': 'QTR',
  'semifinals': 'SEM',
  'finals': 'FIN',
}

export const CONF_TOURNAMENTS: TournamentIdType = {
  'a_ten_tournament': 9,
  'a_sun_tournament': 8,
  'acc_tournament': 6,
  'america_east_tournament': 7,
  'american_athletic_tournament': 43,
  'big_eight_tournament': 10,
  'big_east_tournament': 5,
  'big_sky_tournament': 11,
  'big_south_tournament': 12,
  'big_ten_tournament': 13,
  'big_west_tournament': 15,
  'big_twelve_tournament': 14,
  'c_usa_tournament': 17,
  'caa_tournament': 16,
  'great_west_tournament': 42,
  'horizon_tournament': 20,
  'ivy_tournament': 44,
  'maac_tournament': 21,
  'mac_tournament': 23,
  'meac_tournament': 25,
  'mountain_west_tournament': 27,
  'mvc_tournament': 26,
  'nec_tournament': 28,
  'ovc_tournament': 29,
  'pac_twelve_tournament': 30,
  'patriot_league_tournament': 31,
  'sec_tournament': 32,
  'southern_tournament': 33,
  'southland_tournament': 34,
  'summit_tournament': 24,
  'sun_belt_tournament': 37,
  'swac_tournament': 36,
  'wac_tournament': 39,
  'wcc_tournament': 38,
};

export const ALL_CONFERENCE_TOURNAMENT_IDS: number[] = Object.values(CONF_TOURNAMENTS);
